<template>
  <div class="table-block">
    <section id="binding-product-table">
      <div
        class="products-wrapper"
        style="flex: 1"
      >
        <div class="card p-0">
          <div class="products-header">
            <div class="products-header__left-block">
              <div class="products-header__left-block-categories-name">{{ this.currentCategory?.name }}</div>
              <div class="m-3 products-header__input-box">
                <b-form-input
                  v-model="search_str"
                  type="text"
                  class="large products-header__input"
                  :is-keyup="true"
                  placeholder="Поиск товаров..."
                />
                <b-button
                  id="tooltip-btn-scan"
                  class="icon btn-scan"
                >
                  <img
                    class="scanner"
                    src="/img/icons/mini-scanner.svg"
                    alt="scanner"
                  />
                </b-button>
                <b-tooltip
                  target="tooltip-btn-scan"
                  triggers="hover"
                  custom-class="tooltip-scan"
                >
                  Возможен поиск с помощью считывания штрих-кода сканнером
                </b-tooltip>
              </div>
            </div>
            <b-button
              class="products-header__button"
              @click="addProducts"
              >Добавить товар</b-button
            >
          </div>
          <div
            v-if="currentCategory.id !== undefined"
            class="products-table-container"
          >
            <b-table
              id="products-table"
              ref="table_products"
              :fields="fields"
              :items="products"
              :busy="tableIsBusy"
              :responsive="true"
              thead-tr-class="head-row"
              empty-filtered-text="Нет товаров"
              show-empty
              sticky-header
              no-border-collapse
              class="table-products"
            >
              <template #table-busy>
                <div class="text-center">
                  <b-spinner
                    variant="primary"
                    label="Загрузка..."
                  />
                  <div><strong>Загрузка...</strong></div>
                </div>
              </template>

              <template #head(id)>
                <e-checkbox
                  class="checbox-element-all"
                  :checked="select_all"
                  position="center"
                  @click="changeSelectAll"
                />
              </template>

              <template #cell(id)="{ item }">
                <div class="position-relative checbox-element">
                  <e-checkbox
                    :checked="active(item)"
                    position="center"
                    @click="select(item)"
                  />
                </div>
              </template>

              <template #cell(quantity)="{ item }">
                <div class="quantity-box">
                  <div class="quantity-box__number">
                    <input
                      :ref="`input_${item.id}`"
                      placeholder="0"
                      class="quantity-box__input"
                      :value="getQuantityProducts.find((el) => el.idProduct === item.id)?.quantity || ''"
                      type="number"
                      @input="(event) => changeInpitQuantityProduct(event, item)"
                      @click="(event) => clickOnInput(event, item)"
                    />
                  </div>
                  <div class="quantity-box__button-block">
                    <img
                      class="quantity-box__button-up"
                      src="/img/icons/incoming/quantity_up.svg"
                      alt="up"
                      @click="plusQuantityProduct(item)"
                    />

                    <img
                      class="quantity-box__button-down"
                      src="/img/icons/incoming/quantity_down.svg"
                      alt="down"
                      @click="minusQuantityProduct(item)"
                    />
                  </div>
                </div>
              </template>

              <template #cell(name)="data">
                <div style="display: flex; flex-direction: column">
                  {{ data.value }}
                  <div class="product-info-div">
                    <span class="mr-2">{{ data.item.articul }}</span
                    ><span class="supplier">
                      {{ data.item.supplier?.name ?? '-' }}
                    </span>
                  </div>
                </div>
              </template>

              <template #cell(measurement)="data">
                {{ data.value.name }}
              </template>

              <template #cell(markingType)="data">
                {{ data.value.name }}
              </template>

              <template #cell(remainders)="data">
                {{ getTotalRemainders(data) }}
              </template>

              <template #cell(productType)="data">
                {{ data.value.name }}
              </template>

              <template #cell(markup)="data">
                {{ data.value ? data.value + ' %' : '-' }}
              </template>

              <template #cell(retailPrice)="data"> {{ formatPrice(data.value) }} ₽ </template>

              <template #empty="scope">
                <p class="center mt-3">{{ scope.emptyFilteredText }}</p>
              </template>

              <template #cell(purchasePrice)="data"> {{ formatPrice(data.item?.purchasePrice) }} ₽ </template>

              <template #cell(nds)="data">
                {{ data.item?.taxRate?.name || '-' }}
              </template>

              <template #cell(package)="data">
                {{ getBarcode(data) }}
              </template>

              <template #table-caption>
                <div
                  v-if="items_total > 0"
                  class="p-2"
                >
                  Всего {{ items_total }}
                </div>
              </template>
            </b-table>
          </div>
          <template v-else>
            <div class="center empty-block w-100">
              Выберите группу товаров, создайте группу товаров или загрузите со своей кассы
            </div>
          </template>
        </div>
      </div>
    </section>
    <pagination
      class="m-0"
      :total="items_total"
      @change_pagination="handlerPagination"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import Pagination from '@/views/shared/components/table/Pagination'

  export default {
    components: {
      Pagination
    },
    props: {
      product: {
        type: Object,
        default: () => {}
      },
      document_head: {
        type: String,
        default: () => ''
      },
      type: {
        type: String,
        default: () => ''
      }
    },
    apollo: {
      ProductsV2: {
        query: require('../../../../products/gql/getProducts.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            category: {
              id: this.currentCategory.id
            },
            branch: this.currentBranch.id,
            pagination: this.pagination
          }
        },
        result({ data }) {
          this.items_total = data?.ProductsV2?.total ?? 0
          this.products = data?.ProductsV2?.products ?? []
          this.first = false
          this.tableIsBusy = false
          this.update_category = false
          this.createArrayWithQuantityProducts(this.products)
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        },
        skip() {
          return this.currentCategory.id === undefined
        }
      }
    },

    data: () => ({
      first: true,
      products: [],
      select_all: false,
      update_category: false,
      tableIsBusy: true,
      items_total: 0,
      pagination: {
        skip: 0,
        take: 20
      },
      search_str: '',
      debounce: null
    }),

    computed: {
      ...mapGetters({
        currentCategory: 'products/getCurrentCategory',
        currentBranch: 'settings/getCurrentBranch',
        getSelectedCategories: 'products/getSelectedCategories',
        getExcludedProducts: 'products/getExcludedProducts',
        getSelectedProducts: 'products/getSelectedProducts',
        getCurrentCategoryMenuId: 'products/getCurrentCategoryMenuId',
        getQuantityProducts: 'products/getQuantityProducts'
      }),

      fields: function () {
        return [
          {
            key: 'id',
            label: '',
            isRowHeader: true,
            class: 'center p-0',
            thStyle: 'width: 50px'
          },
          {
            key: 'quantity',
            label: 'Кол-во',
            class: 'center',
            thStyle: 'width: 100px',
            thClass: 'white-bg'
          },
          {
            key: 'name',
            label: 'Наименование',
            thStyle: 'width: 350px',
            thClass: 'white-bg',
            sortable: true
          },
          {
            key: 'retailPrice',
            label: 'Розн. цена',
            class: 'center',
            thStyle: 'width: 100px',
            thClass: 'white-bg'
          },
          {
            key: 'remainders',
            label: 'Остаток',
            class: 'center',
            thStyle: 'width: 100px',
            thClass: 'white-bg'
          },
          {
            key: 'measurement',
            class: 'center',
            label: 'Ед. изм.',
            thStyle: 'width: 50px',
            thClass: 'white-bg'
          },
          {
            key: 'purchasePrice',
            label: 'Закупочная цена',
            class: 'center',
            thStyle: 'width: 100px',
            thClass: 'white-bg'
          },
          {
            key: 'markup',
            label: 'Наценка',
            class: 'center',
            thStyle: 'width: 100px',
            thClass: 'white-bg'
          },
          {
            key: 'nds',
            label: 'НДС',
            class: 'center',
            thStyle: 'width: 100px',
            thClass: 'white-bg'
          },
          {
            key: 'package',
            label: 'Штрих код',
            class: 'center',
            thStyle: 'width: 100px',
            thClass: 'white-bg'
          }
        ]
      }
    },

    watch: {
      products(newProducts) {
        let currentSelectedAfterPagination = newProducts.filter((product) => {
          return this.getSelectedProducts.some((el) => product.id === el.id)
        })
        if (currentSelectedAfterPagination.length === this.products.length) {
          this.select_all = true
        } else {
          this.select_all = false
        }
      },

      currentCategory() {
        this.pagination.skip = 0
        this.pagination.take = 20
      },

      async search_str(newVal) {
        clearTimeout(this.debounce)
        this.debounce = setTimeout(async () => {
          this.tableIsBusy = true
          const { data } = await this.$apollo.query({
            query: require('../../../../products/gql/searchProduct.gql'),
            variables: {
              branch: this.currentBranch?.id,
              name: newVal
            },
            fetchPolicy: 'no-cache'
          })
          if (data) {
            this.products = data?.ProductSearch?.products ?? []
            this.items_total = data?.ProductSearch?.total ?? 0
          } else this.products = []
          this.tableIsBusy = false
        }, 500)
      }
    },

    mounted() {
      document.body.classList.add('body-page-products')
      this.listenerKeyUp()
    },

    destroyed() {
      document.body.classList.remove('body-page-products')
    },

    created() {
      this.SET_QUANTITY_PRODUCTS([])
    },

    methods: {
      ...mapActions({
        setExcludedProducts: 'products/setExcludedProducts',
        setSelectedProducts: 'products/setSelectedProducts'
      }),
      ...mapMutations({
        SET_QUANTITY_PRODUCTS: 'products/SET_QUANTITY_PRODUCTS'
      }),

      addProducts() {
        this.$emit('addSelected')
      },

      changeSelectAll() {
        this.select_all = !this.select_all
        if (!this.select_all) {
          let select = this.getSelectedProducts
          this.products.forEach((obj) => (select = select.filter((item) => obj.id !== item.id)))
          return this.setSelectedProducts(select)
        }

        this.products.forEach((item) => {
          const id = item.id
          if (!this.getSelectedProducts.some((obj) => obj.id === id)) {
            this.setSelectedProducts([...this.getSelectedProducts, item])
          }
        })

        let arrayQuantityProducts = []
        this.products.forEach((productItem) => {
          if (!this.getQuantityProducts.some((el) => el?.idProduct === productItem.id)) {
            arrayQuantityProducts.push({
              idProduct: productItem.id,
              quantity: ''
            })
          }
        })
        this.SET_QUANTITY_PRODUCTS([...this.getQuantityProducts, ...arrayQuantityProducts])
      },

      createArrayWithQuantityProducts(product) {
        let arrayQuantityProducts = []
        product.forEach((productItem) => {
          if (!this.getQuantityProducts.some((el) => el?.idProduct === productItem.id)) {
            arrayQuantityProducts.push({
              idProduct: productItem.id,
              quantity: ''
            })
          }
        })
        this.SET_QUANTITY_PRODUCTS([...this.getQuantityProducts, ...arrayQuantityProducts])
      },

      clickOnInput(event, item) {
        event.target.select()

        if (!this.getSelectedProducts.some((obj) => obj.id === item.id)) {
          this.setSelectedProducts([...this.getSelectedProducts, item])
        } else {
          return
        }
      },

      changeInpitQuantityProduct(event, item) {
        const currentArrayQuantity = this.getQuantityProducts
        currentArrayQuantity.forEach((el) => {
          if (el.idProduct === item.id) {
            if (!event.target.value == '') {
              el.quantity = +event.target.value
            } else {
              el.quantity = 0
            }
          }
        })
        this.SET_QUANTITY_PRODUCTS(currentArrayQuantity)

        if (!this.getSelectedProducts.some((obj) => obj.id === item.id)) {
          this.setSelectedProducts([...this.getSelectedProducts, item])
        } else {
          return
        }
      },

      plusQuantityProduct(item) {
        const currentArrayQuantity = this.getQuantityProducts
        currentArrayQuantity.forEach((el) => {
          if (el.idProduct === item.id) {
            el.quantity++
          }
        })
        this.SET_QUANTITY_PRODUCTS(currentArrayQuantity)

        if (!this.getSelectedProducts.some((obj) => obj.id === item.id)) {
          this.setSelectedProducts([...this.getSelectedProducts, item])
        } else {
          return
        }
      },

      minusQuantityProduct(item) {
        const currentArrayQuantity = this.getQuantityProducts

        currentArrayQuantity.forEach((el) => {
          if (el.idProduct === item.id) {
            if (el.quantity === 1) {
              this.setSelectedProducts(this.getSelectedProducts.filter((obj) => obj.id !== item.id))
            }
            if (el.quantity == 0) return
            el.quantity--
          }
        })

        this.SET_QUANTITY_PRODUCTS(currentArrayQuantity)
      },

      active(item) {
        return this.getSelectedProducts.some((obj) => obj.id === item.id)
      },

      select(val) {
        if (!this.getSelectedProducts.some((obj) => obj.id === val.id)) {
          this.setSelectedProducts([...this.getSelectedProducts, val])
          this.$refs['input_' + val.id].focus()
        } else {
          this.setSelectedProducts(this.getSelectedProducts.filter((obj) => obj.id !== val.id))
          let quantityProductArr = this.getQuantityProducts
          quantityProductArr.forEach((el) => {
            if (el.idProduct === val.id) {
              el.quantity = 0
            }
          })
        }

        let currentSeleted = this.products.filter((product) => {
          return this.getSelectedProducts.some((el) => product.id === el.id)
        })
        if (currentSeleted.length < this.products.length) this.select_all = false
        if (currentSeleted.length === this.products.length) this.select_all = true
      },

      formatPrice(summ = 0) {
        const val = summ === null ? 0 : summ
        const formatter = new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB',
          maximumFractionDigits: 2,
          minimumFractionDigits: 0
        })

        return formatter.format(val)
      },
      formatNumber(summ = 0) {
        const val = summ === null ? 0 : summ
        const formatter = new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB',
          maximumFractionDigits: 2,
          minimumFractionDigits: 0
        })
        return formatter.format(val)
      },

      getTotalRemainders({ item }) {
        let total = 0
        if (item.productType?.id === '52a4eab0-55c2-49f1-a152-f02cbc61e0b8') return '-'
        if (!item.remainders) return 0

        item.remainders.forEach((i) => {
          total = total + i.value
        })

        return this.formatNumber(total)
      },

      getBarcode({ item }) {
        return item?.package?.[0]?.barcode?.barcode ?? '-'
      },

      listenerKeyUp() {
        const regexpRu = /^[?!,.а-яА-ЯёЁ0-9]+$/
        const regexpEn = /^[?!,.a-zA_Z0-9]+$/
        let interval = null

        window.addEventListener('keydown', (item) => {
          if (item.target.localName === 'input' || item.altKey || item.ctrlKey || item.metaKey) return
          const key = item.key
          if (interval) clearInterval(interval)
          if (this.time === 0) interval = setInterval(this.counterSeconds, 100)

          if (item.target.attributes.isKeyup || key === 'Alt') return false
          if (key === 'Escape' || key === 'Delete') return (this.search_str = '')

          if (key === 'Backspace') return (this.search_str = this.search_str.substring(0, this.search_str.length - 1))

          if (key === 'Enter') {
            if (this.time < 1) {
              this.$apollo
                .mutate({
                  mutation: require('../../../../products/gql/checkBarcode.gql'),
                  variables: {
                    barcode: this.search_str
                  }
                })
                .then(({ data }) => {
                  const statusOnServe = data?.CheckBarcode?.status ?? false

                  if (!statusOnServe) {
                    this.resetTimer()
                    return this.modal_scan()
                  }
                })
                .catch((e) => {
                  console.error('error: ', e)
                })
            }

            this.resetTimer()
            clearInterval(interval)
          }

          if (regexpRu.test(key) || regexpEn.test(key))
            if (typeof item.key === 'string') return (this.search_str = this.search_str + key)

          this.handler_search_str = this.handler_search_str + key
        })
      },

      async handlerPagination({ current_page = null, take = null }) {
        this.tableIsBusy = true

        if (current_page) this.pagination.skip = Number((current_page - 1) * take)
        if (take) this.pagination.take = take

        await this.$apollo.queries.ProductsV2.refetch()
        // if (this.select_all) {
        //   this.products.forEach((item) => {
        //   const id = item.id
        // if (this.select_all) {
        //   this.products.forEach((item) => {
        //   const id = item.id

        //   if (!this.getSelectedProducts.some((obj) => obj.id === id)) {
        //     this.setSelectedProducts([...this.getSelectedProducts, item])
        //   }
        // })
        //   if (!this.getSelectedProducts.some((obj) => obj.id === id)) {
        //     this.setSelectedProducts([...this.getSelectedProducts, item])
        //   }
        // })

        // this.products.forEach((item) => {
        //   const id = item.id
        //   if (
        //      !this.getSelectedProducts.includes(id)
        //    && !this.getExcludedProducts.includes(id)
        //   )

        //     this.setSelectedProducts([...this.getSelectedProducts, item])
        // })
        //}
        //}
      }
    }
  }
</script>

<style scoped lang="scss">
  .table-block {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-bottom: 5px;
    width: 75%;
  }
  ::v-deep() {
    table#products-table {
      min-width: 1100px;

      tr {
        cursor: pointer;

        th {
          border-bottom: 1px solid #e1e1e1;
          border-top: none;
        }

        td {
          border-bottom: 1px solid #e1e1e1;
          border-left: none;
          border-right: none;
          border-bottom: 1px solid #e1e1e1;
        }
      }
    }

    .products__item {
      overflow-y: hidden;
    }

    .products-wrapper {
      & > .card {
        overflow: auto;
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-bottom: 22px !important;

        .products-table-container {
          display: flex;
          flex-direction: column;
          flex: 1;
        }

        .table-products {
          display: flex;
          flex-direction: column;
          flex: 1;
          margin-bottom: 0;
        }

        .head-row > th:first-child {
          border-right: none !important;
        }
      }

      .custom-checkbox {
        padding: 0;
        width: 100%;
        height: 100%;

        .custom-control-label {
          width: 100%;
          min-height: 48px;
          cursor: pointer;

          &:before,
          &:after {
            top: 50%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
            box-shadow: none;
          }

          &:after {
            top: 50%;
            left: 50%;
          }
        }
      }
    }

    .link-product {
      text-decoration: none;
      color: #313131;
      font-weight: 500;

      &:hover {
        color: #0066ff;
      }
    }
  }

  #binding-product-table {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    .table-b-table-default + .white-bg {
      background-color: white !important;
      border-bottom: 1px solid #e1e1e1 !important;
    }

    .body-page-products .wrapper__inner {
      height: 100%;
      overflow-y: hidden;
      padding: 24px 20px 15px;
    }

    .scanner {
      margin-left: 12px;
    }

    .products-wrapper {
      height: 100%;
      margin-bottom: 0;
      display: flex;
      flex-direction: column;

      .products-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &__left-block {
          display: flex;
          flex-direction: row;
          align-items: start;
          justify-content: center;
        }
        &__left-block-categories-name {
          margin-top: auto;
          margin-bottom: auto;
          border-radius: 2px;
          background: var(--gray-gray-50, #efefef);
          color: var(--text-primary-gray-700, #313131);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          width: 170px;
          padding-top: 4px;
          padding-bottom: 4px;
          padding-left: 5px;
          padding-right: 5px;
          white-space: nowrap;
          overflow: hidden;
          padding: 5px;
          text-overflow: ellipsis;
          margin-left: 16px;
        }
        &__input-box {
          position: relative;
          width: 230px;
        }
        &__input {
        }
        &__button {
          border-radius: 2px;
          background: var(--text-system-blue-400, #00a3ff);
          margin-right: 16px;
          color: var(--gray-white, #fff);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }
      }

      .product .empty-block {
        border-top: 1px solid #e1e1e1;
        padding-top: 16px;
      }

      & > .card {
        height: 100%;
        overflow: auto;
        padding-bottom: 0;

        .table-products {
          min-height: 100% !important;
          margin-bottom: 0;
        }

        .head-row > th:first-child {
          border-right: none !important;
        }
      }

      .quantity-box {
        width: 50px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        &__number {
        }
        &__input {
          width: 100%;
          padding-left: 10px;
          border-radius: 2px;
          border: 1px solid var(--text-placeholder-gray-300, #bcbcbc);
          background: var(--secondary-b-0, #fff);
        }
        &__button-block {
          margin-right: 8px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 5;
          right: 0;
        }
        &__button-up {
          margin-bottom: 2px;
          height: 9px;
        }
        &__button-down {
          height: 9px;
          margin-top: 2px;
        }
      }

      .custom-checkbox {
        .custom-control-label {
          &:before,
          &:after {
            top: 50%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
            box-shadow: none;
          }

          &:after {
            top: 50%;
            left: 50%;
          }
        }
      }
    }

    .product-info-div {
      display: flex;
      flex-direction: row;
      width: 90%;
      max-width: 380px;

      & > span {
        color: #888888;
        font-weight: 500;
      }

      & .supplier {
        color: #888888;
        font-weight: 500;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-height: 1rem;
      }
    }

    .btn-scan {
      position: absolute;
      top: 0;
      right: 12px;

      width: 25px;
      height: 31px;
      margin: 0 !important;
      justify-content: center;
      cursor: default !important;

      &:hover,
      &:active,
      &:focus {
        background: none !important;
      }
    }

    .no-margin {
      margin-bottom: 0 !important;
    }
  }
  .table-responsive {
    &::-webkit-scrollbar {
      height: 7px;
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bcbcbc;
      border-radius: 10px;
    }

    .checbox-element {
      margin-left: 16px;
    }
    .checbox-element-all {
      margin-left: 7px;
    }
  }
</style>
