var render = function render(){
  var _this$currentCategory;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-block"
  }, [_c('section', {
    attrs: {
      "id": "binding-product-table"
    }
  }, [_c('div', {
    staticClass: "products-wrapper",
    staticStyle: {
      "flex": "1"
    }
  }, [_c('div', {
    staticClass: "card p-0"
  }, [_c('div', {
    staticClass: "products-header"
  }, [_c('div', {
    staticClass: "products-header__left-block"
  }, [_c('div', {
    staticClass: "products-header__left-block-categories-name"
  }, [_vm._v(_vm._s((_this$currentCategory = this.currentCategory) === null || _this$currentCategory === void 0 ? void 0 : _this$currentCategory.name))]), _c('div', {
    staticClass: "m-3 products-header__input-box"
  }, [_c('b-form-input', {
    staticClass: "large products-header__input",
    attrs: {
      "type": "text",
      "is-keyup": true,
      "placeholder": "Поиск товаров..."
    },
    model: {
      value: _vm.search_str,
      callback: function ($$v) {
        _vm.search_str = $$v;
      },
      expression: "search_str"
    }
  }), _c('b-button', {
    staticClass: "icon btn-scan",
    attrs: {
      "id": "tooltip-btn-scan"
    }
  }, [_c('img', {
    staticClass: "scanner",
    attrs: {
      "src": "/img/icons/mini-scanner.svg",
      "alt": "scanner"
    }
  })]), _c('b-tooltip', {
    attrs: {
      "target": "tooltip-btn-scan",
      "triggers": "hover",
      "custom-class": "tooltip-scan"
    }
  }, [_vm._v(" Возможен поиск с помощью считывания штрих-кода сканнером ")])], 1)]), _c('b-button', {
    staticClass: "products-header__button",
    on: {
      "click": _vm.addProducts
    }
  }, [_vm._v("Добавить товар")])], 1), _vm.currentCategory.id !== undefined ? _c('div', {
    staticClass: "products-table-container"
  }, [_c('b-table', {
    ref: "table_products",
    staticClass: "table-products",
    attrs: {
      "id": "products-table",
      "fields": _vm.fields,
      "items": _vm.products,
      "busy": _vm.tableIsBusy,
      "responsive": true,
      "thead-tr-class": "head-row",
      "empty-filtered-text": "Нет товаров",
      "show-empty": "",
      "sticky-header": "",
      "no-border-collapse": ""
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('b-spinner', {
          attrs: {
            "variant": "primary",
            "label": "Загрузка..."
          }
        }), _c('div', [_c('strong', [_vm._v("Загрузка...")])])], 1)];
      },
      proxy: true
    }, {
      key: "head(id)",
      fn: function () {
        return [_c('e-checkbox', {
          staticClass: "checbox-element-all",
          attrs: {
            "checked": _vm.select_all,
            "position": "center"
          },
          on: {
            "click": _vm.changeSelectAll
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(id)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "position-relative checbox-element"
        }, [_c('e-checkbox', {
          attrs: {
            "checked": _vm.active(item),
            "position": "center"
          },
          on: {
            "click": function ($event) {
              return _vm.select(item);
            }
          }
        })], 1)];
      }
    }, {
      key: "cell(quantity)",
      fn: function (_ref2) {
        var _vm$getQuantityProduc;

        var item = _ref2.item;
        return [_c('div', {
          staticClass: "quantity-box"
        }, [_c('div', {
          staticClass: "quantity-box__number"
        }, [_c('input', {
          ref: `input_${item.id}`,
          staticClass: "quantity-box__input",
          attrs: {
            "placeholder": "0",
            "type": "number"
          },
          domProps: {
            "value": ((_vm$getQuantityProduc = _vm.getQuantityProducts.find(function (el) {
              return el.idProduct === item.id;
            })) === null || _vm$getQuantityProduc === void 0 ? void 0 : _vm$getQuantityProduc.quantity) || ''
          },
          on: {
            "input": function (event) {
              return _vm.changeInpitQuantityProduct(event, item);
            },
            "click": function (event) {
              return _vm.clickOnInput(event, item);
            }
          }
        })]), _c('div', {
          staticClass: "quantity-box__button-block"
        }, [_c('img', {
          staticClass: "quantity-box__button-up",
          attrs: {
            "src": "/img/icons/incoming/quantity_up.svg",
            "alt": "up"
          },
          on: {
            "click": function ($event) {
              return _vm.plusQuantityProduct(item);
            }
          }
        }), _c('img', {
          staticClass: "quantity-box__button-down",
          attrs: {
            "src": "/img/icons/incoming/quantity_down.svg",
            "alt": "down"
          },
          on: {
            "click": function ($event) {
              return _vm.minusQuantityProduct(item);
            }
          }
        })])])];
      }
    }, {
      key: "cell(name)",
      fn: function (data) {
        var _data$item$supplier$n, _data$item$supplier;

        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-direction": "column"
          }
        }, [_vm._v(" " + _vm._s(data.value) + " "), _c('div', {
          staticClass: "product-info-div"
        }, [_c('span', {
          staticClass: "mr-2"
        }, [_vm._v(_vm._s(data.item.articul))]), _c('span', {
          staticClass: "supplier"
        }, [_vm._v(" " + _vm._s((_data$item$supplier$n = (_data$item$supplier = data.item.supplier) === null || _data$item$supplier === void 0 ? void 0 : _data$item$supplier.name) !== null && _data$item$supplier$n !== void 0 ? _data$item$supplier$n : '-') + " ")])])])];
      }
    }, {
      key: "cell(measurement)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.value.name) + " ")];
      }
    }, {
      key: "cell(markingType)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.value.name) + " ")];
      }
    }, {
      key: "cell(remainders)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(_vm.getTotalRemainders(data)) + " ")];
      }
    }, {
      key: "cell(productType)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.value.name) + " ")];
      }
    }, {
      key: "cell(markup)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.value ? data.value + ' %' : '-') + " ")];
      }
    }, {
      key: "cell(retailPrice)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(_vm.formatPrice(data.value)) + " ₽ ")];
      }
    }, {
      key: "empty",
      fn: function (scope) {
        return [_c('p', {
          staticClass: "center mt-3"
        }, [_vm._v(_vm._s(scope.emptyFilteredText))])];
      }
    }, {
      key: "cell(purchasePrice)",
      fn: function (data) {
        var _data$item;

        return [_vm._v(" " + _vm._s(_vm.formatPrice((_data$item = data.item) === null || _data$item === void 0 ? void 0 : _data$item.purchasePrice)) + " ₽ ")];
      }
    }, {
      key: "cell(nds)",
      fn: function (data) {
        var _data$item2, _data$item2$taxRate;

        return [_vm._v(" " + _vm._s(((_data$item2 = data.item) === null || _data$item2 === void 0 ? void 0 : (_data$item2$taxRate = _data$item2.taxRate) === null || _data$item2$taxRate === void 0 ? void 0 : _data$item2$taxRate.name) || '-') + " ")];
      }
    }, {
      key: "cell(package)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(_vm.getBarcode(data)) + " ")];
      }
    }, {
      key: "table-caption",
      fn: function () {
        return [_vm.items_total > 0 ? _c('div', {
          staticClass: "p-2"
        }, [_vm._v(" Всего " + _vm._s(_vm.items_total) + " ")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 3404669027)
  })], 1) : [_c('div', {
    staticClass: "center empty-block w-100"
  }, [_vm._v(" Выберите группу товаров, создайте группу товаров или загрузите со своей кассы ")])]], 2)])]), _c('pagination', {
    staticClass: "m-0",
    attrs: {
      "total": _vm.items_total
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }