<template>
  <b-modal
    id="categories-modal"
    body-class="p-0"
    header-class="p-0"
    centered
    hide-header
    hide-footer
    @shown="getCategories"
  >
    <h4 style="padding: 0px 0px 16px 16px; margin: 0">Группы товаров</h4>
    <div style="border-top: 1px solid #e1e1e1; border-bottom: 1px solid #e1e1e1">
      <div
        class="m-3"
        style="font-size: 16px; cursor: pointer; display: flex; flex-direction: row"
      >
        <b-checkbox
          :checked="isAllSelected"
          @change="selectAll"
        />
        <span @click="selectAll">Выбрать все</span>
      </div>
      <div style="height: 50vh; overflow-y: auto; overflow-x: hidden">
        <menu-item
          :items="menu_arr"
          :open_group="openGroup"
          :update_menu="groupAddedSuccessfully"
          :current_ids="current_ids"
          :selected_categories="selected_categories"
          :is_select_group="true"
          :is_multiple="true"
          @selected_group="selectedGroup"
          @select_group="selectGroup"
        />
      </div>
    </div>
    <div
      class="d-flex"
      style="padding: 12px"
    >
      <b-button
        variant="primary"
        style="width: 100%; margin-right: 8px; display: block"
        @click="addCategories(true)"
      >
        Добавить
      </b-button>
      <b-button
        variant="outline-primary"
        style="width: 100%; display: block"
        @click="hideModal"
      >
        Отмена
      </b-button>
    </div>
  </b-modal>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import MenuItem from '@/views/products/components/menu/MenuItem'
  import * as R from 'ramda'
  export default {
    name: 'CategoriesLoadModal',
    components: { MenuItem },
    apollo: {
      Categories: {
        query: require('../../../products/gql/getCategories.gql'),
        fetchPolicy: 'no-cache',
        variables: {
          level: 0
        },
        result({ data }) {
          const categories = R.pathOr([], ['Categories'], data)

          this.menu_arr = this.updateCategories(categories, this.current_category_path)

          this.menu_is_loading = false
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },
      Category: {
        query: require('../../../products/gql/getCategory.gql'),
        fetchPolicy: 'no-cache',
        manual: true,
        variables() {
          return {
            id: this.current_group_id
          }
        },
        result({ data }) {
          const category = R.pathOr([], ['Category'], data)
          const isOpen = this.current_ids?.includes(this.current_group_id)
          this.setCurrentsIds(category.path || [])
          if (isOpen) {
            this.setCurrentsIds(this.current_ids.filter((id) => id !== this.current_group_id))
          }
          this.addChild(category)
          this.$apollo.queries.Category.skip = true
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        },
        watchLoading(isLoading) {
          this.menu_is_loading = isLoading
        },
        skip() {
          return true
        }
      }
    },

    props: {
      modal_show: {
        type: Boolean,
        default: true
      },
      included_categories: {
        type: Array,
        default: () => []
      },
      enabled_categories: {
        type: Array,
        default: () => []
      }
    },

    computed: {
      ...mapGetters({
        current_ids: 'products/getCurrentIds'
      })
    },

    data: function () {
      return {
        menu_is_loading: true,
        menu_arr: [],
        selected_categories: [],
        isAllSelected: false
      }
    },
    watch: {
      included_categories(newVal) {
        this.selected_categories = newVal
      }
    },
    methods: {
      ...mapActions({
        setCurrentCategory: 'products/setCurrentCategory',
        setCurrentCategoryPath: 'products/setCurrentCategoryPath',
        setCurrentsIds: 'products/setCurrentIds'
      }),

      getCategories() {
        this.selected_categories = JSON.parse(JSON.stringify(this.included_categories))

        if (this.enabled_categories?.length) {
          this.menu_arr = this.menu_arr?.filter(
            (el) =>
              this.enabled_categories?.includes(el.id) ||
              el.path.some((el) => this.enabled_categories?.includes(el)) ||
              el.child_groups.some((el) => this.enabled_categories?.includes(el.id))
          )
        }
      },

      handlerFormGroup() {
        this.setCurrentCategory(this.selected_category)
        this.hideModal()
      },

      hideModal() {
        this.$bvModal.hide('categories-modal')
      },
      addCategories(hide = false) {
        this.$emit('add-categories', this.selected_categories)
        if (hide) this.hideModal()
      },

      addChild(items) {
        this.menu_arr = this.recursionMenu(this.menu_arr, items)

        if (this.getItemsIds(this.current_category_path)?.includes(items.id)) {
          this.setCurrentCategoryPath(this.getCurrentCategoriesPath(this.current_category_path, items))
        } else {
          this.setCurrentCategoryPath(items)
        }
      },

      recursionMenu(items, childs) {
        const new_items = R.clone(items)

        return new_items?.map((item) => {
          if (item.id === childs.id) {
            item.child_groups = childs.child_groups?.map((child) => {
              if (child.child_groups) child.child_groups = null

              return child
            })
          } else if (item.child_groups) {
            item.child_groups = this.recursionMenu(item.child_groups, childs)
          }

          return item
        })
      },

      getItemsIds(items, curId = null) {
        let result = []

        if (items?.id) {
          result.push(items.id)
          if (items.id !== curId && items?.child_groups?.length) {
            items.child_groups.forEach((t) => {
              result = [...result, ...this.getItemsIds(t)]
            })
          }
        }
        return result
      },

      openGroup({ id }) {
        this.current_group_id = id
        if (this.current_ids?.includes(id)) {
          this.setCurrentsIds(this.current_ids.filter((cId) => cId !== id))
        } else {
          this.$nextTick(() => {
            this.$apollo.queries.Category.skip = false
            this.$apollo.queries.Category.refetch()
          })
        }
      },

      updateCategories(categories, current) {
        const updateCategories = [...categories]

        if (current) {
          updateCategories.forEach((category, index) => {
            if (category.id === current.id) {
              updateCategories[index] = current
            }
          })
        }

        return updateCategories
      },

      groupAddedSuccessfully() {
        this.$apollo.queries.Categories.refetch()
      },

      async selectAll() {
        this.isAllSelected = !this.isAllSelected
        const categories = []
        if (this.isAllSelected)
          for (let item of this.menu_arr) {
            const CATEGORIES = ['level_one', 'level_two', 'level_three', 'level_four']
            const { data } = await this.$apollo.query({
              query: require('../../gql/Categories.graphql'),
              variables: {
                input: {
                  [CATEGORIES[item.level]]: item.id
                }
              }
            })
            if (data?.Categories) {
              categories.push(...data.Categories.map((el) => el.id))
            }
            categories.push(item.id)
          }
        this.selected_categories = categories
        this.addCategories()
      },

      selectedGroup(category) {
        if (!this.selected_categories?.includes(category.id)) this.selected_categories.push(category.id)
        else this.selected_categories = this.selected_categories.filter((el) => el !== category.id)
      },
      async selectGroup({ category, value }) {
        const CATEGORIES = ['level_one', 'level_two', 'level_three', 'level_four']
        const { data } = await this.$apollo.query({
          query: require('../../gql/Categories.graphql'),
          variables: {
            input: {
              [CATEGORIES[category.level]]: category.id
            }
          }
        })
        if (value) {
          if (data.Categories) this.selected_categories.push(...data.Categories.map((el) => el.id))
        } else
          this.selected_categories = this.selected_categories.filter(
            (el) => !data.Categories?.some((category) => category.id === el)
          )
      }
    }
  }
</script>

<style scoped></style>
