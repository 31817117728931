var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-between align-items-center product-info",
    class: {
      normal: !_vm.product.hide
    },
    on: {
      "click": _vm.openProduct
    }
  }, [_c('div', {
    staticClass: "text-left",
    staticStyle: {
      "width": "85%"
    }
  }, [_vm._v(" " + _vm._s(_vm.product.name) + " "), _c('div', {
    staticClass: "b-name article text-left"
  }, [_vm._v(" " + _vm._s(_vm.info) + " ")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }