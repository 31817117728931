var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "params-body"
  }, [_c('div', {
    staticClass: "card pb-0 pt-0"
  }, [_c('b-form', {
    staticClass: "form"
  }, [_c('b-row', {
    staticClass: "w-100"
  }, [_c('b-col', {
    staticClass: "mt-16",
    attrs: {
      "cols": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Дата",
      "label-for": "work_period"
    }
  }, [_vm.fixed ? _c('div', {
    staticClass: "text_column"
  }, [_vm._v(" " + _vm._s(_vm.convertDate(_vm.form.date)) + " ")]) : [_c('date-picker', {
    staticClass: "elm-calendar",
    attrs: {
      "id": "work_period",
      "placeholder": "Выберите дату",
      "clearable": false,
      "format": "dd.MM.yyyy",
      "is-keyup": true
    },
    on: {
      "on-change": _vm.cangeDate
    },
    model: {
      value: _vm.form.date,
      callback: function ($$v) {
        _vm.$set(_vm.form, "date", $$v);
      },
      expression: "form.date"
    }
  })]], 2)], 1), _c('b-col', {
    staticClass: "mt-16",
    attrs: {
      "cols": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Период учета остатка",
      "label-for": "accounting_period"
    }
  }, [_vm.fixed ? _c('div', {
    staticClass: "text_column"
  }, [_vm._v(" " + _vm._s(_vm.getAccountingPeriodName) + " ")]) : [_c('i-select', {
    attrs: {
      "id": "accounting-period",
      "value": _vm.filter_params.accounting_period
    },
    on: {
      "on-select": _vm.changeAccountingPeriod
    }
  }, [_c('i-option', {
    attrs: {
      "value": "begin"
    }
  }, [_vm._v("Начало дня")]), _vm._l(_vm.accounting_period_list, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  })], 2)]], 2)], 1), _c('b-col', {
    staticClass: "mt-16",
    attrs: {
      "cols": "3"
    }
  }, [_c('b-form-group', {
    class: {
      error: _vm.error_storage
    },
    attrs: {
      "label": "Место хранения",
      "label-for": "storage"
    }
  }, [_vm.fixed ? _c('div', {
    staticClass: "text_column"
  }, [_vm._v(" " + _vm._s(_vm.form.storage.name) + " ")]) : [_c('i-select', {
    attrs: {
      "id": "storage",
      "filterable": ""
    },
    on: {
      "on-select": _vm.changeStorage
    },
    model: {
      value: _vm.form.storage.id,
      callback: function ($$v) {
        _vm.$set(_vm.form.storage, "id", $$v);
      },
      expression: "form.storage.id"
    }
  }, _vm._l(_vm.storages_list, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)]], 2)], 1), _c('b-col', {
    staticClass: "mt-16",
    attrs: {
      "cols": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Тип инвентаризации",
      "label-for": "type"
    }
  }, [[_c('i-select', {
    attrs: {
      "id": "type",
      "disabled": _vm.fixed,
      "is-keyup": true
    },
    on: {
      "on-change": _vm.changeType
    },
    model: {
      value: _vm.form.type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, _vm._l(_vm.inventory_type_list, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id,
        "label": item.name
      }
    }, [_c('div', {
      staticStyle: {
        "display": "flex",
        "flex-direction": "row",
        "align-items": "center",
        "min-width": "100%"
      }
    }, [_c('span', [_vm._v(_vm._s(item.name))]), _c('img', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.right",
        value: item.tooltip,
        expression: "item.tooltip",
        modifiers: {
          "hover": true,
          "right": true
        }
      }],
      staticClass: "ml-3",
      staticStyle: {
        "height": "16px",
        "width": "16px"
      },
      attrs: {
        "src": "/img/icons/icon-info.svg",
        "alt": ""
      }
    })])]);
  }), 1)]], 2)], 1), _c('b-col', {
    staticClass: "mt-16",
    attrs: {
      "cols": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Цена учета",
      "label-for": "price"
    }
  }, [_vm.fixed ? [_vm._v(_vm._s(_vm.form.price_type.name))] : _vm._e(), _c('i-select', {
    attrs: {
      "id": "price",
      "disabled": _vm.fixed
    },
    on: {
      "on-change": _vm.changePriceType
    },
    model: {
      value: _vm.form.price_type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "price_type", $$v);
      },
      expression: "form.price_type"
    }
  }, _vm._l(_vm.price_type_list, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 2)], 1), _c('b-col', {
    staticClass: "pr-0 mt-16",
    attrs: {
      "xl": "2",
      "lg": "4",
      "md": "4",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    class: {
      error: _vm.is_entity_error
    },
    attrs: {
      "label": "Организация",
      "label-for": "entity"
    }
  }, [_vm.fixed ? [_vm._v(" " + _vm._s(_vm.filter_params.entity.name) + " ")] : _c('e-select', {
    attrs: {
      "selected_type": "text",
      "error": _vm.is_entity_error,
      "options": _vm.entity_list,
      "select_name": "entity_select"
    },
    scopedSlots: _vm._u([{
      key: "custom_name",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "entity"
        }, [_c('p', {
          staticClass: "name"
        }, [_vm._v(_vm._s(item.name))]), _c('div', {
          staticClass: "inn_kpp"
        }, [_c('p', {
          staticClass: "inn"
        }, [_vm._v("ИНН: " + _vm._s(item.inn ? item.inn : '-'))]), _c('p', {
          staticClass: "kpp"
        }, [_vm._v("КПП: " + _vm._s(item.kpp ? item.kpp : '-'))])])])];
      }
    }]),
    model: {
      value: _vm.active_entity,
      callback: function ($$v) {
        _vm.active_entity = $$v;
      },
      expression: "active_entity"
    }
  })], 2)], 1)], 1), _c('b-row', {
    staticClass: "w-100"
  }, [_c('b-col', [_c('div', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-extraparams",
      modifiers: {
        "collapse-extraparams": true
      }
    }],
    staticClass: "btn-extra-param cursor",
    staticStyle: {
      "margin-bottom": "16px"
    }
  }, [_c('span', {
    staticClass: "isHide"
  }, [_vm._v("Показать")]), _c('span', {
    staticClass: "isShow"
  }, [_vm._v("Скрыть")]), _vm._v(" дополнительные параметры ")]), _c('b-collapse', {
    staticClass: "row",
    attrs: {
      "id": "collapse-extraparams"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    staticStyle: {
      "margin-bottom": "16px"
    },
    attrs: {
      "label": "Комментарий",
      "label-for": "comment"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "comment",
      "rows": "3",
      "disabled": _vm.fixed,
      "max-rows": "6",
      "is-keyup": true
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('change_comment', _vm.form.comment);
      }
    },
    model: {
      value: _vm.form.comment,
      callback: function ($$v) {
        _vm.$set(_vm.form, "comment", $$v);
      },
      expression: "form.comment"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }