var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "categories-modal",
      "body-class": "p-0",
      "header-class": "p-0",
      "centered": "",
      "hide-header": "",
      "hide-footer": ""
    },
    on: {
      "shown": _vm.getCategories
    }
  }, [_c('h4', {
    staticStyle: {
      "padding": "0px 0px 16px 16px",
      "margin": "0"
    }
  }, [_vm._v("Группы товаров")]), _c('div', {
    staticStyle: {
      "border-top": "1px solid #e1e1e1",
      "border-bottom": "1px solid #e1e1e1"
    }
  }, [_c('div', {
    staticClass: "m-3",
    staticStyle: {
      "font-size": "16px",
      "cursor": "pointer",
      "display": "flex",
      "flex-direction": "row"
    }
  }, [_c('b-checkbox', {
    attrs: {
      "checked": _vm.isAllSelected
    },
    on: {
      "change": _vm.selectAll
    }
  }), _c('span', {
    on: {
      "click": _vm.selectAll
    }
  }, [_vm._v("Выбрать все")])], 1), _c('div', {
    staticStyle: {
      "height": "50vh",
      "overflow-y": "auto",
      "overflow-x": "hidden"
    }
  }, [_c('menu-item', {
    attrs: {
      "items": _vm.menu_arr,
      "open_group": _vm.openGroup,
      "update_menu": _vm.groupAddedSuccessfully,
      "current_ids": _vm.current_ids,
      "selected_categories": _vm.selected_categories,
      "is_select_group": true,
      "is_multiple": true
    },
    on: {
      "selected_group": _vm.selectedGroup,
      "select_group": _vm.selectGroup
    }
  })], 1)]), _c('div', {
    staticClass: "d-flex",
    staticStyle: {
      "padding": "12px"
    }
  }, [_c('b-button', {
    staticStyle: {
      "width": "100%",
      "margin-right": "8px",
      "display": "block"
    },
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.addCategories(true);
      }
    }
  }, [_vm._v(" Добавить ")]), _c('b-button', {
    staticStyle: {
      "width": "100%",
      "display": "block"
    },
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v(" Отмена ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }