<template>
  <div class="b-table__wrapper">
    <mass-add-product @add_products="createSpec" />
    <categories-load-modal
      :included_categories="includedCategories"
      @add-categories="addCategories"
    />
    <result-modal
      :extra="filter_params"
      :comment="comment"
      :items="sheet.items"
      :total="sheet.total"
      :loading_sheet="loading_sheet"
      @fixed="makeFixed"
      @load_items="loadSheetItems"
      @change_comment="changeCommentFromRezultModal"
    />
    <product-movement-modal :product="product_list?.find((el) => el.id === selected[0])?.product" />
    <div class="b-table__filter d-flex justify-content-between">
      <div class="b-toggle-button">
        <e-button
          size="m"
          class="btn-filters cursor"
          @click="changeVisibleParams"
        >
          Параметры документа
          <img
            class="ml-3"
            :class="{ rotate: visibleParams }"
            src="/img/icons/arrow_up.svg"
            alt=""
          />
        </e-button>
      </div>
      <div class="b-action-buttons d-flex">
        <e-button
          v-if="!filter_params.fixed"
          size="m"
          variant="outline-primary"
          @click="back"
        >
          Сохранить как черновик
        </e-button>
        <e-button
          size="m"
          :disabled="loadingFixed || is_request_update"
          :loading="loadingFixed || is_request_update"
          variant="primary"
          class="ml-3"
          @click="handlerDocument(filter_params.fixed)"
        >
          {{ filter_params.fixed ? 'Редактировать' : 'Продолжить' }}
        </e-button>
        <b-dropdown
          no-caret
          variant="none"
          class="ml-3"
        >
          <template #button-content>
            <div class="btn-more">
              <img
                src="/img/icons/more.svg"
                alt=""
              />
            </div>
          </template>
          <b-dropdown-item @click="printList"> Печать: Форма “Список для Просчета” </b-dropdown-item>
          <b-dropdown-item @click="printSheet"> Печать: Форма “Сличительная Ведомость“ </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <e-collapse
      id="filters-col"
      v-model="visibleParams"
      :show-button="false"
    >
      <template #content>
        <inventory-filters
          ref="inventory_filters"
          :fixed="filter_params.fixed"
          :filter_params="filter_params"
          :error_storage="errorStorage"
          :is_entity_error="entityError"
          @change_date="changeDate"
          @change_accounting_period="changeAccountingPeriod"
          @change_storage="changeStorage"
          @change_comment="changeComment"
          @change_type="changeType"
          @change_entity="changeEntity"
          @change_price_type="changePriceType"
          @set_error_storage="setErrorStorage"
          @set_error_entity="setErrorEntity"
        />
      </template>
    </e-collapse>
    <div class="b-table__content">
      <div class="table-filter">
        <b-form-input
          v-model="filter"
          type="text"
          class="filter-search"
          placeholder="Поиск"
          :is-keyup="true"
          @input="filterSearch"
        />
        <div
          style="
            display: flex;
            flex-direction: row;
            margin: 0 16px;
            padding: 0 12px;
            background: #efefef;
            height: 32px;
            align-items: center;
            border-radius: 2px;
            cursor: pointer;
          "
          @click.stop.prevent="
            table.diff_only = !table.diff_only
            updateProductTable()
          "
        >
          <b-checkbox
            v-model="table.diff_only"
            switch
          />
          <span style="color: #313131; font-size: 14px">Показывать только с расхождением</span>
        </div>
        <b-button
          v-if="currentType === 'categories'"
          @click="showCategories"
        >
          Загрузка групп товаров
        </b-button>
        <b-dropdown
          no-caret
          variant="none"
          class="ml-3"
          style="height: 32px; width: 32px"
        >
          <template #button-content>
            <div class="btn-more">
              <img
                src="/img/icons/settings.svg"
                alt=""
              />
            </div>
          </template>
          <b-dropdown-item v-b-modal.sort-table-modal> Изменить столбцы </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="table-inner">
        <resizable-table
          :resizable="false"
          :foot_row="false"
          table_name="invspec-docs"
          :default_fields="fields"
          :items="product_list"
          :busy="tableIsBusy"
          :inside_card="false"
          empty_text="Ничего не найдено"
          @scroll-down="scrollDown"
          @scroll-up="scrollUp"
          @sort_change="sortChange"
        >
          <template #table-busy>
            <div
              style="width: 100%"
              class="text-center my-2"
            >
              <b-spinner
                variant="primary"
                label="Загрузка..."
              />
              <div class="mt-2"><strong>Загрузка...</strong></div>
            </div>
          </template>

          <template #head_id>
            <b-form-checkbox v-model="select_all" />
          </template>

          <template #body_id="data">
            <b-form-checkbox
              v-model="selected"
              class="cursor"
              :value="data.item.id"
            />
          </template>

          <template #body_name="data">
            <product-spec-info :product="data.item.product" />
          </template>

          <template #body_measurement="data">
            <span>{{ data.item.product?.measurement?.name }}</span>
          </template>
          <template #body_remainder="data">
            {{ +data.item.remainder.toFixed(3) || 0 }}
          </template>

          <template #body_delta="data">
            <div class="count">
              <table-row-input
                id="tooltip-target-1"
                :ref="data.item.id + 'delta'"
                :value="data.item.delta"
                :fixed="filter_params.fixed"
                :input_id="data.item.id + 'delta'"
                :digits="3"
                @handle-input="(val) => setDelta(val, data.item, true)"
                @input="(val) => setDelta(val, data.item)"
                @on-focus="centeredScroll"
              />
            </div>
          </template>
          <template #body_status="data">
            <b-badge variant="primary">{{ data.item.status }}</b-badge>
          </template>

          <template #body_diff="data">
            {{ +data.item.diff.toFixed(3) || 0 }}
          </template>

          <template #body_diff_sum="{ item }">
            <!--            {{Math.abs(data.item.balance-data.item.delta)*data.item.price}}-->
            {{ +item.diff_sum.toFixed(3) || 0 }}
          </template>
          <template #body_manager="data">
            {{ data.item.created_by?.last_name }}
            {{ data.item.created_by?.first_name }}
          </template>

          <template #body_empty="scope">
            <p class="center mt-3">{{ scope.emptyFilteredText }}</p>
          </template>

          <!-- FOOTER -->
          <template #foot_product><span></span></template>
          <template #foot_order><span></span> </template>
          <template #foot_name><span></span></template>
          <template #foot_balance>
            <span class="foot-col">Итого: {{ table_total.deltaTotal?.toLocaleString().split(',')[0] || 0 }}</span>
          </template>
          <template #foot_delta>
            <span style="padding-right: 22px">Итого: {{ table_total.factTotal?.toLocaleString() || 0 }}</span>
          </template>
          <template #foot_measurement><span></span></template>
          <template #foot_difference>
            <span>{{ table_total.diffTotal?.toLocaleString().split(',')[0] || 0 }}</span>
          </template>
          <template #foot_difference_sum>
            <span>{{ formatPrice(table_total.sumTotal).toLocaleString() }} ₽</span>
          </template>
          <template #foot_manager><span></span></template>
          <template #footer-block>
            <div class="table-footer incoming-table-footer align-middle d-flex align-items-center p-3">
              <div class="mr-auto product-search-block">
                <product-search
                  v-if="!filter_params.fixed"
                  style="width: 250px"
                  class="ml-2 product-search"
                  :document_head="extra_params.id"
                  :document="extra_params"
                  document_type="iventarisation"
                  @select_product="select_product"
                />
                <e-button
                  v-if="!filter_params.fixed"
                  class="button-add-mass-product"
                  @click="showMassAddProduct"
                >
                  <img
                    src="/img/icons/mass-add.svg"
                    alt="addProducts"
                    style="position: absolute"
                  />
                </e-button>
              </div>

              <div class="footer-data mr-5">
                Остаток учет:
                {{ table_total.deltaTotal?.toLocaleString() || 0 }}
              </div>

              <div class="footer-data mr-5">Остаток факт: {{ table_total.factTotal?.toLocaleString() || 0 }}</div>

              <div class="footer-data mr-5">Разница кол-во: {{ table_total.diffTotal?.toLocaleString() || 0 }}</div>

              <div class="footer-data">Разница сумма: {{ formatPrice(table_total.sumTotal).toLocaleString() }} ₽</div>
            </div>
          </template>
        </resizable-table>
      </div>
      <table-navbar
        style="margin: -55px 0 0 !important"
        :items="selected"
        :clear_selected="clearSelected"
        items_name="inventory"
        @remove_items="removeProducts"
        @show_move="showMove"
      />
    </div>
  </div>
</template>

<script>
  import TableRowInput from '@/views/operational-processes/components/TableRowInput'
  import TableNavbar from '@/views/shared/components/table/TableNavbar'
  import ResizableTable from '@/components/ResizableTable'
  // import { bus } from '@/main'
  import { mapActions, mapGetters } from 'vuex'
  import * as R from 'ramda'
  import InventoryFilters from '@/views/operational-processes/components/inventory/InventoryFilters'
  import CategoriesLoadModal from '@/views/operational-processes/components/inventory/CategoriesLoadModal'
  import ResultModal from '@/views/operational-processes/components/inventory/ResultModal'
  import { http } from '@/http/http'
  import ProductMovementModal from '@/views/products/modal/ProductMovementModal.vue'
  import ProductSpecInfo from '@/views/operational-processes/components/ProductSpecInfo.vue'
  import ProductSearch from '../ProductSearch.vue'
  import MassAddProduct from '@/views/operational-processes/components/incoming/modal/MassAddProduct'
  import { print_html } from '@/utils/print'

  export default {
    components: {
      ProductSpecInfo,
      ProductMovementModal,
      ResultModal,
      CategoriesLoadModal,
      InventoryFilters,
      TableNavbar,
      ResizableTable,
      TableRowInput,
      ProductSearch,
      MassAddProduct
    },

    props: {
      fields: {
        type: Array,
        default: () => []
      }
    },

    apollo: {
      InventarisationHead: {
        query: require('../../pages/inventory/gql/InventoryHead.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            id: this.$route.params.id
          }
        },
        result({ data }) {
          const head = data.InventarisationHead
          const dt = new Date(head.date).getTime() + new Date().getTimezoneOffset() * 60 * 1000

          this.table_total = head.total
          this.extra_params = {
            id: head.id,
            date: new Date(dt),
            fixed: head.fixed,
            number: head.number,
            storage: head.storage,
            entity: head.entity,
            comment: head.comment,
            type: head.type ?? 'document',
            accounting_period: head.time,
            price_type: head.price_type ?? 'purchase'
          }
          this.includedCategories = head.includedCategories ?? []

          this.syncParams(this.extra_params)
          this.$nextTick(() => {
            if (!this.isUpdateFilters) return this.syncParams(this.extra_params)

            this.isUpdateFilters = false
          })
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        }
      },
      InvSpecs: {
        query: require('../../pages/inventory/gql/InvSpecs.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            document_head: this.$route.params.id,
            pagination: this.table
          }
        },
        async result({ data }) {
          if (this.first) {
            this.product_list = data.InvSpecs.specification || []
            this.table.prev_cursor = data.InvSpecs.prev_cursor
            this.table.next_cursor = data.InvSpecs.next_cursor
          } else {
            if (this.up) {
              const initialHeight = document.querySelector('.table-docs').scrollHeight
              this.product_list.unshift(...data.InvSpecs.specification)
              this.table.prev_cursor = data.InvSpecs.prev_cursor
              this.$nextTick(() => {
                document.querySelector('.table-docs').scrollTop =
                  document.querySelector('.table-docs').scrollHeight - initialHeight - 70
              })
            } else {
              this.product_list.push(...data.InvSpecs.specification)
              this.table.next_cursor = data.InvSpecs.next_cursor
            }
          }
          this.first = false
          this.tableIsBusy = false
          this.loading = false
        }
      }
    },
    data() {
      return {
        visibleParams: false,
        loadingFixed: false,
        activeEntity: [],
        errorStorage: null,
        sheet: {
          items: [],
          total: {
            fact_total: 0,
            delta_total: 0,
            difference_total: 0,
            difference_sum_total: 0
          }
        },
        table_total: {},
        filter_params: {},
        showDiff: false,
        select_all: false,
        selected: [],
        product_list: [],
        includedCategories: [],
        taxRateList: [],
        filter: '',
        currentNewDate: '',
        currentAccountingPeriod: null,
        currentStorageId: '',
        currentEntityId: '',
        currentPriceType: 'purchase',
        currentType: 'document',
        comment: null,
        product: {
          id: ''
        },
        total: 0,

        table: {
          search: '',
          next_cursor: '',
          prev_cursor: '',
          take: 20,
          diff_only: false,
          order: [
            {
              key: 'order',
              value: 'ASC'
            }
          ]
        },
        search_result: false,
        search_prodcut_name: '',
        time: 0,
        search_by_scanner: false,
        handler_product: false,
        loading_sheet: true,
        extra_params: {
          date: '',
          fixed: '',
          id: '',
          number: '',
          comment: '',
          storage: null,
          type: 'document',
          accounting_period: 'begin',
          price_type: 'purchase'
        },
        isEditDelta: false,
        is_request_update: false,
        entityError: false,
        isSupplierError: false,
        isUpdatedStorage: false,
        isUpdatedEntity: false,
        isUpdatedType: false,
        isUpdatedPriceType: false,
        edit_form: {
          delta: null,
          price: null,
          sum: null,
          nds: null,
          retailPrice: null
        },
        tableIsBusy: true,
        loading: true,
        isUpdateFilters: false,
        tableSheet: {
          total: 0,
          skip: 0,
          take: 20
        },
        forwardDirection: true,
        first: true,
        timer: null
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch',
        currentDocument: 'operprocess/getCurrentDocument',
        getQuantityProducts: 'products/getQuantityProducts'
      }),

      selectedProducts: function () {
        return this.product_list?.filter((product) => this.selected.includes(product.id))
      }
    },

    watch: {
      'extra_params.type'() {
        this.currentType = this.extra_params.type
      },
      select_all(newVal) {
        if (!newVal) return (this.selected = [])

        this.product_list?.forEach((item) => {
          const id = item.id
          if (!this.selected.includes(id)) this.selected.push(id)
        })
      },

      product_list() {
        setTimeout(() => {
          this.$nextTick(() => {
            const table = document.querySelector('.table-docs')
            if (table.scrollHeight === table.clientHeight) {
              this.scrollDown()
            }
          })
        }, 100)
      },

      handler_product(newVal) {
        if (newVal) {
          document.removeEventListener('click', this.listenerClick, false)
        } else {
          document.addEventListener(
            'click',
            (e) => {
              this.listenerClick(e)
            },
            false
          )
        }
      }
    },
    beforeMount() {
      this.visibleParams =
        localStorage.getItem('newDocHeader') === 'true' || localStorage.getItem('docHeader') === 'true'
    },
    mounted() {
      localStorage.setItem('invspec-docs_fields', JSON.stringify(this.fields))
      // window.localStorage.getItem('incoming_fields')
      document.addEventListener(
        'click',
        (e) => {
          this.listenerClick(e)
        },
        false
      )
      this.listenerKeyUp()

      if (this.currentDocument.id) {
        this.extrapdate_params = R.mergeLeft(this.currentDocument, this.extra_params)
        // this.tableIsBusy = false
        return false
      }
    },

    destroyed() {
      localStorage.setItem('newDocHeader', 'false')
      this.isUpdatedStorage = false
      document.removeEventListener('click', () => console.info('removeListener'))
    },

    methods: {
      ...mapActions({
        setCurrentDocument: 'operprocess/setCurrentDocument'
      }),

      filterSearch() {
        setTimeout(() => {
          this.table.search = this.filter
          this.table.next_cursor = ''
          this.table.prev_cursor = ''
          this.first = true
          this.loading = true
          this.$apollo.queries.InvSpecs.refetch()
        }, 600)
      },

      sortChange(data) {
        this.table.search = this.filter
        this.table.next_cursor = ''
        this.table.prev_cursor = ''
        this.first = true
        this.loading = true
        this.table.order[0].key = data.key
        this.table.order[0].value = data.sort
        this.$apollo.queries.InvSpecs.refetch()
      },

      clearSelected() {
        this.selected = []
        this.select_all = false
      },

      showMassAddProduct() {
        this.$bvModal.show('mass_add_product')
      },

      changeVisibleParams() {
        this.visibleParams = !this.visibleParams
        if (this.visibleParams) {
          localStorage.setItem('docHeader', 'true')
        } else {
          localStorage.setItem('docHeader', 'false')
        }
      },

      async scrollDown() {
        if (!this.table.next_cursor || this.loading) return
        this.up = false
        this.loading = true
        await this.$apollo.queries.InvSpecs.refresh()
      },
      async scrollUp() {
        if (!this.table.prev_cursor || this.loading) return
        this.up = true
        this.loading = true
        this.table.next_cursor = ''
        await this.$apollo.queries.InvSpecs.refresh()
      },
      edit_product() {
        this.$router.push({
          name: 'products.edit',
          params: { name: this.getProduct.name, id: this.getProduct.id }
        })
      },

      setErrorStorage() {
        this.errorStorage = null
      },

      setErrorEntity() {
        this.entityError = null
      },

      setDefault() {
        this.saveFields()
      },
      saveFields() {
        localStorage.setItem('invspec-docs_fields', JSON.stringify(this.fields))
        this.$apollo.mutate({
          mutation: require('../../gql/saveFields.graphql'),
          variables: {
            input: {
              table_name: 'invspec-docs',
              fields: this.fields.map((el) => {
                return {
                  key: el.key,
                  checked: el.checked,
                  width: el.width,
                  label: el.label
                }
              })
            }
          }
        })
      },

      scroll_to(item) {
        setTimeout(() => {
          this.$refs[item + 'delta']?.showInput()
          this.$nextTick(() => {
            console.log(item, this.$refs)
            this.$refs[item + 'delta'].$el?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'end' })
          })
        }, 100)
      },

      centeredScroll({ offset }) {
        const clientWidth = document.querySelector('.table-docs').clientWidth
        document.querySelector('.table-docs').scrollTo({ left: offset - clientWidth / 2, behavior: 'smooth' })
      },

      showCategories() {
        this.$bvModal.show('categories-modal')
      },

      makeFixed() {
        this.changeDocumentStatus(true)
        this.$bvModal.hide('inventory-result')
        this.$router.back()
      },

      addCategories(categories) {
        this.$apollo
          .mutate({
            mutation: require('../../pages/inventory/gql/UpdateInventoryHead.graphql'),
            variables: {
              id: this.currentDocument.id,
              includedCategories: categories
            }
          })
          .then(() => {
            this.$apollo.queries.InventarisationHead.refetch()
          })
          .catch((err) => {
            console.log(err)
            this.$noty.show(`Что-то пошло не так`)
          })
        this.$bvModal.hide('categories-modal')
      },
      updateHead(params) {
        clearTimeout(this.timer)
        this.timer = setTimeout(async () => {
          await this.$apollo.mutate({
            mutation: require('../../pages/inventory/gql/UpdateInventoryHead.graphql'),
            variables: {
              date: this.currentNewDate,
              type: this.currentType,
              price_type: this.currentPriceType,
              entity: this.currentEntityId?.id,
              comment: this.comment || this.currentDocument.comment,
              storage: this.currentStorageId,
              id: this.currentDocument.id || (this.$route.query.id ? this.$route.query.id : this.$route.params.id),
              time: this.currentAccountingPeriod
            }
          })
          await this.$apollo.queries.InventarisationHead.refetch()
          if (params?.update_spec) this.updateProductTable()
        }, 500)
      },

      showMove() {
        this.$bvModal.show('product-movement')
      },

      getCurrentComment() {
        const form = this.filtersData()
        this.comment = form.comment
      },

      getCurrentDate() {
        const form = this.filtersData()
        form.date = new Date(form.date.setHours(12, 0, 0, 0) - form.date.getTimezoneOffset() * 60000)
        this.currentNewDate = form.date
      },

      changeAccountingPeriod(date) {
        this.currentAccountingPeriod = date.value
        this.updateHead({ update_spec: true })
      },

      getCurrentStorageId() {
        const form = this.filtersData()
        this.currentStorageId = form.storage.id
      },

      getCurrentEntityId() {
        const form = this.filtersData()
        this.currentEntityId = form.entity
      },

      getCurrentType() {
        const form = this.filtersData()
        this.currentType = form.type
        if (this.currentType === 'categories') this.showCategories()
      },

      async setDelta(val, item) {
        const current = item
        current.delta = val
        this.updateProduct(current)
      },

      getCurrentPriceType() {
        const form = this.filtersData()
        this.currentPriceType = form.price_type
      },

      getCurrentProd(id) {
        const product = R.propEq('id', id)
        const current = R.filter(product, this.product_list)

        return current[0]
      },
      filtersData() {
        return this.$refs.inventory_filters.form
      },

      async handlerDocument(doc_status) {
        try {
          this.loadingFixed = true
          if (this.filter_params.storage?.id === null) {
            this.errorStorage = true
            return this.$noty.error('Документ должен иметь место хранения')
          }
          if (!this.currentEntityId?.id) {
            this.entityError = true
            return this.$noty.error('Выберите организацию')
          }
          if (this.currentType === 'document') {
            if (!this.product_list?.length) return this.$noty.error('Для продолжения добавьте товар')
          }
          if (!doc_status) {
            this.$bvModal.show('inventory-result')
            this.loading_sheet = true
            await this.$apollo.mutate({
              mutation: require('../../gql/GenerateInventorySheet.graphql'),
              variables: {
                input: {
                  id: this.currentDocument.id
                }
              }
            })
            this.loading_sheet = false
            this.tableSheet.skip = 0
            const { data } = await this.$apollo.query({
              query: require('../../gql/ReadInventorySheet.graphql'),
              fetchPolicy: 'no-cache',
              variables: {
                input: {
                  document_head: this.currentDocument.id,
                  skip: this.tableSheet.skip,
                  take: this.tableSheet.take
                }
              }
            })
            let sheet = data?.ReadInventorySheet ?? null
            this.sheet.items = data?.ReadInventorySheet?.sheets ?? []
            this.sheet.total = {
              fact_total: sheet.fact_total,
              delta_total: sheet.delta_total,
              difference_sum_total: sheet.difference_sum_total,
              difference_total: sheet.difference_total
            }
            this.tableSheet.total = data?.ReadInventorySheet?.total
            return
          }
          this.isRequestUpdate(true)
          this.filter_params = this.filtersData()

          if (doc_status) return this.changeDocumentStatus()

          if (this.validationHead()) this.$nextTick(() => this.updateDocument(true))
        } catch (e) {
          this.loadingFixed = false
        } finally {
          this.loadingFixed = false
        }
        this.loadingFixed = false
      },
      async loadSheetItems() {
        if (this.table.skip >= this.tableSheet.total) return
        this.tableSheet.skip += this.tableSheet.take
        const { data } = await this.$apollo.query({
          query: require('../../gql/ReadInventorySheet.graphql'),
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              document_head: this.currentDocument.id,
              skip: this.tableSheet.skip,
              take: this.tableSheet.take
            }
          }
        })
        const items = data?.ReadInventorySheet?.sheets ?? []
        this.sheet.items = [...this.sheet.items, ...items]
        this.tableSheet.total = data?.ReadInventorySheet?.total
      },
      changeEntity() {
        this.isUpdatedEntity = true
        this.tableIsBusy = true
        this.product_list = []

        this.getCurrentEntityId()
        this.isUpdateFilters = true
        this.updateProductTable()
        this.updateHead()
      },

      changeComment() {
        this.getCurrentComment()
        this.updateHead()
      },

      changeCommentFromRezultModal(data) {
        this.comment = data
        this.updateHead()
      },

      changeStorage() {
        this.isUpdatedStorage = true
        this.tableIsBusy = true
        this.product_list = []

        this.getCurrentStorageId()
        this.isUpdateFilters = true
        this.updateHead({ update_spec: true })
      },

      changeDate() {
        // this.isUpdatedType = true
        this.tableIsBusy = true
        this.product_list = []
        this.getCurrentDate()
        this.isUpdateFilters = true
        this.updateHead({ update_spec: true })
      },

      changeType(val) {
        this.isUpdatedType = true
        this.tableIsBusy = true
        this.product_list = []
        this.currentType = val
        this.getCurrentType()
        this.isUpdateFilters = true
        this.updateHead({ update_spec: true })
      },

      changePriceType() {
        this.isUpdatedPriceType = true
        this.tableIsBusy = true
        this.product_list = []

        this.getCurrentPriceType()
        this.isUpdateFilters = true
        this.updateHead({ update_spec: true })
      },

      syncParams(items) {
        this.extra_params.date = R.pathOr('', ['date'], items)
        this.extra_params.fixed = R.pathOr('', ['fixed'], items)
        this.extra_params.id = R.pathOr('', ['id'], items)
        this.extra_params.number = R.pathOr('', ['number'], items)
        this.extra_params.storage = R.pathOr('', ['storage'], items)
        this.extra_params.comment = R.pathOr('', ['comment'], items)
        this.extra_params.entity = items?.entity ?? {}
        this.extra_params.price_type = items.price_type ?? 'purchase'
        this.extra_params.type = items.type ?? 'document'
        this.extra_params.accounting_period = items.accounting_period ?? 'begin'
        this.setCurrentDocument(this.extra_params)
        this.filter_params = items

        if (this.isUpdatedStorage) {
          this.filter_params = R.omit(['storage'], this.filter_params)
        }

        this.$emit('sync_filter_params', this.filter_params)
      },

      formatPrice(summ = 0) {
        const val = summ === null ? 0 : summ
        return Number(Number.parseFloat(val).toFixed(2))
      },

      search_product() {
        if (this.search_prodcut_name.length >= 2) return (this.search_result = true)

        this.search_result = false
      },

      searchProductResult(items) {
        const result = R.pathOr({}, ['ProductSearch', 'products'], items)

        if (result.length === 1) this.select_product(result[0])

        return items
      },

      async select_product(product) {
        const productChange = {
          product_id: product.id,
          delta: 0,
          price: 0,
          retailPrice: 0,
          sum: 0
        }
        this.search_result = false
        this.search_prodcut_name = ''
        const new_spec = await this.addProduct(productChange)
        if (!new_spec) return
        this.forwardDirection = true
        this.firstLoading = true
        this.first = true
        this.loading = true
        this.table.next_cursor = new_spec.next_cursor
        await this.$apollo.queries.InvSpecs.refetch()
        if (new_spec.specification?.[0]?.id) {
          this.scroll_to(new_spec.specification?.[0]?.id)
        }
      },

      reduceRemainders(item) {
        const form = this.filtersData()
        const storage_id = form.storage.id

        if (item.balance > 0) return item.balance

        if (item?.product?.remainders) {
          return (
            item?.product?.remainders
              .filter((el) => el?.storage?.id === storage_id)
              .reduce((sum, el) => sum + el.value, 0) || 0
          )
        }

        return 0
      },

      removeProduct() {
        this.product = {}
        this.handler_product = false
      },

      isRequestUpdate(status = false) {
        this.is_request_update = status
      },

      setSupplierError(status = false) {
        this.isSupplierError = status
      },

      validationHead() {
        return true
      },

      clearInput() {
        this.search_prodcut_name = ''
      },

      counterSeconds() {
        this.time = this.time + 0.1
      },

      resetTimer() {
        this.time = 0
      },

      updateProductTable() {
        this.table.next_cursor = ''
        this.table.prev_cursor = ''
        this.product_list = []
        this.tableIsBusy = true
        this.first = true
        this.loading = true

        this.$apollo.queries.InvSpecs.refetch()
      },

      // Action's
      back() {
        this.filter_params = this.filtersData()
        this.$nextTick(() => this.updateDocument())
      },

      createSpec(products) {
        const currentArrProducts = []
        products.forEach((elProduct) => {
          const delta = this.getQuantityProducts.find((el) => el.idProduct === elProduct.id).quantity

          const product = {
            product_id: elProduct.id,
            delta: delta === '' ? 0 : delta,
            price: 0,
            retailPrice: elProduct.retailPrice,
            sum: 0
          }
          currentArrProducts.push(product)
        })

        this.$apollo
          .mutate({
            mutation: require('../../pages/inventory/gql/CreateInventorySpec.graphql'),
            variables: {
              document_head: this.currentDocument.id,
              productChange: currentArrProducts
            }
          })
          .then(({ data }) => {
            this.table.next_cursor = data?.CreateInvSpec.next_cursor
            this.first = true
            this.$apollo.queries.InvSpecs.refetch()
          })
          .catch((e) => {
            console.error('error: ', e)
            this.$noty.show(`Что-то пошло не так. Попробуйте еще раз`)
          })
      },

      async addProduct(productChange) {
        const find = this.product_list?.find((obj) => obj.product.id === productChange.product_id)
        if (find) {
          this.scroll_to(find.id)
          return
        }

        try {
          const { data } = await this.$apollo.mutate({
            mutation: require(`../../pages/inventory/gql/CreateInventorySpec.graphql`),
            variables: {
              document_head: this.currentDocument.id,
              productChange: productChange
            }
          })
          if (data) {
            this.$noty.show('Добавлено')
          }
          this.removeProduct()
          return data.CreateInvSpec
        } catch (err) {
          console.log(err)
          this.$noty.error('Документ должен иметь место хранения')
        }
      },

      updateSpec(data) {
        const product = this.product_list?.find((obj) => obj.id === data.id)
        product.diff = data.diff ?? product.diff
        product.diff_sum = data.diff_sum ?? product.diff_sum
      },

      updateProduct(productChange) {
        this.$apollo
          .mutate({
            mutation: require(`../../pages/inventory/gql/UpdateInventarisationSpecification.graphql`),
            variables: {
              input: {
                document_head: this.currentDocument.id,
                id: productChange.id,
                delta: +productChange.delta
              }
            }
          })
          .then(({ data }) => {
            this.updateSpec(data?.UpdateInventarisationSpecification)
            this.$apollo.queries.InventarisationHead.refetch()
          })
          .catch((e) => {
            console.error('error: ', e)
            this.$noty.show(
              e.graphQLErrors[0]?.extensions?.ru ?? `При добавлении товара что-то пошло не так. Попробуйте еще раз`
            )
          })
        this.isEditDelta = -1
      },

      removeProducts(items) {
        this.tableIsBusy = true
        this.$apollo
          .mutate({
            mutation: require('../../pages/inventory/gql/RemoveInventorySpec.graphql'),
            variables: {
              document_head: this.currentDocument.id,
              ids: items
            }
          })
          .then(({ data }) => {
            this.product_list = []
            const statusOnServer = R.pathOr(false, [`RemoveInventSpecification`, 'status'], data)

            if (statusOnServer) {
              this.updateProductTable()
            } else {
              this.$noty.show(`Ошибка при удалении товара`)
              this.tableIsBusy = false
            }
          })
          .catch((e) => {
            console.error('error: ', e)
            this.tableIsBusy = false
          })

        this.selected = []
        this.select_all = false
      },

      updateDocument(changeStatus = false) {
        //TODO update
        const head = this.filter_params
        const specifications = []

        const variables = {
          id: this.$route.params.id || this.currentDocument.id,
          branch: this.currentBranch.id,
          storage: head.storage?.id,
          entity: head.entity?.id,
          type: head.type ?? 'document',
          price_type: head.price_type ?? 'purchase',
          date: head.date,
          operation: head.operation,
          supplier: head.supplier?.id || null,
          comment: head.comment,
          specifications: specifications
        }

        if (changeStatus && this.product_list.length < 1) {
          this.isRequestUpdate()
          this.$noty.show(`Необходимо добавить товары в документ`)

          return false
        }

        this.$apollo
          .mutate({
            mutation: require('../../pages/inventory/gql/UpdateInventoryHead.graphql'),
            variables: variables
          })
          .then(() => {
            if (!changeStatus) {
              this.$nextTick(() =>
                this.$router.push({
                  name: `operation-process.overhead.inventory`
                })
              )
            } else {
              this.changeDocumentStatus(true)
            }

            this.isRequestUpdate()
          })
          .catch(() => this.$noty.show(`Что-то пошло не так. Попробуйте еще раз`))
      },

      changeDocumentStatus(status = false) {
        this.$apollo
          .mutate({
            mutation: require(`../../pages/inventory/gql/ChangeInventoryStatus.graphql`),
            variables: {
              id: this.$route.params.id || this.currentDocument.id,
              fixed: status
            }
          })
          .then(({ data }) => {
            const status = R.pathOr(false, ['ChangeInventarisationStatus', 'fixed'], data)

            this.$set(this.filter_params, 'fixed', status)
            this.$emit('sync_filter_params', this.filter_params)
            this.isRequestUpdate()
            this.updateProductTable()
          })
          .catch((e) => {
            console.error(e)
            this.$noty.show(`Что-то пошло не так. Попробуйте еще раз`)
          })
      },

      listenerClick(e) {
        const target = e.target.classList.value

        if (
          target === 'cursor' ||
          target === 'span-count' ||
          target === 'span-count error' ||
          target === 'edit' ||
          target === 'ivu-select-placeholder' ||
          target === 'ivu-icon ivu-icon-ios-arrow-down ivu-select-arrow' ||
          target === 'form-control'
        )
          return true

        this.hideHandlerInputs()
      },

      listenerKeyUp() {
        const regexpRu = /^[?!,.а-яА-ЯёЁ0-9]+$/
        const regexpEn = /^[?!,.a-zA_Z0-9]+$/
        let interval = null

        window.addEventListener('keydown', (item) => {
          if (item.target.localName === 'input') return
          const key = item.key
          if (interval) clearInterval(interval)
          if (this.time === 0) interval = setInterval(this.counterSeconds, 100)

          if (item.target.attributes.isKeyup || key === 'Alt') return false
          if (key === 'Escape' || key === 'Delete') return (this.search_prodcut_name = '')

          if (key === 'Backspace')
            return (this.search_prodcut_name = this.search_prodcut_name.substring(
              0,
              this.search_prodcut_name.length - 1
            ))

          if (key === 'Enter') {
            if (this.time < 1) {
              this.search_by_scanner = true
            }
            this.search_product()

            this.resetTimer()
            clearInterval(interval)
          }

          if (regexpRu.test(key) || regexpEn.test(key))
            if (typeof item.key === 'string') return (this.search_prodcut_name = this.search_prodcut_name + key)

          // this.handler_search_str = this.handler_search_str + key
        })
      },

      // Input's Handlers
      handlerDelta(id) {
        const current = this.getCurrentProd(id)
        current.delta = this.edit_form?.delta
        current.diff = current.delta - current.remainder
        this.updateProduct(current)
        // this.mergeCalcedDataProduct(current, index)
      },

      handlerPrice(id, index) {
        const current = this.getCurrentProd(id)
        current.price = this.edit_form.price
        const calcData = this.calcProduct(current, 'retail_new')

        this.mergeCalcedDataProduct(calcData, index)
      },

      hideHandlerInputs() {
        this.isEditDelta = false
      },

      // Calc data
      calcSum(item) {
        const rems = Math.abs(this.reduceRemainders(item) - item.delta)
        const price = this.currentPriceType === 'retail' ? item.product.retailPrice : item.product.purchasePrice
        return rems * price
      },

      mergeCalcedDataProduct(product, index) {
        this.product_list[index] = R.mergeLeft(product, this.product_list[index])

        this.$refs.table_docs.refresh()

        this.$nextTick(() => this.hideHandlerInputs())
      },

      // Toggles handlers
      toggleDeltaInput(val, id) {
        if (this.extra_params.fixed) return false

        this.hideHandlerInputs()
        this.isEditDelta = this.isEditDelta === id ? false : id
        this.edit_form.delta = val

        // this.$nextTick(() => this.$refs.delta.select())
      },

      // Расчёт footer total
      getTotalDelta() {
        let count = 0
        this.product_list.forEach((item) => (count = count + +item.delta))
        return count
      },

      getTotalBalance() {
        let count = 0
        this.product_list.forEach((item) => (count = count + this.reduceRemainders(item)))
        return count
      },

      getTotalSumm() {
        let sum = 0
        this.product_list.forEach((item) => (sum = sum + this.calcSum(item)))
        return sum.toFixed(2)
      },

      getTotalNdsSumm() {
        let ndsSumm = 0
        this.product_list.forEach(({ nds_summ }) => {
          const nds = Number(nds_summ)
          ndsSumm = ndsSumm + nds
        })

        return ndsSumm.toFixed(2)
      },

      getMeanMarkup() {
        let total = 0
        let meanMarkup = 0

        this.product_list.forEach((item) => {
          const markup = Number(item.markup)

          if (markup && markup !== 0) {
            total++
            meanMarkup = meanMarkup + markup
          }
        })

        if (meanMarkup === 0 || total === 0) return 0

        return Number(meanMarkup / total).toFixed(2)
      },
      getNextSpecification(event) {
        let bottomOfWindow = event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight
        if (bottomOfWindow) {
          if (this.table.skip >= this.total) return
          this.table.skip +=
            this.table.take + this.table.skip > this.total ? this.total - this.table.skip : this.table.take
          this.$apollo.queries.InventarisationHead.refetch()
        }
      },
      remove() {},
      async printList() {
        const data = await http.get('/templates/inventarisation/list?id=' + this.extra_params.id)
        print_html(data.data)
      },
      async printSheet() {
        if (!this.extra_params.fixed) {
          await this.$apollo.mutate({
            mutation: require('../../gql/GenerateInventorySheet.graphql'),
            variables: {
              input: {
                id: this.currentDocument.id
              }
            }
          })
        }
        const data = await http.get('/templates/inventarisation/sheet?id=' + this.extra_params.id)
        print_html(data.data)
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .p-0 {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .b-search {
      &__wrapper {
        position: relative;
        // width: 100%;
        width: 250px !important;
        z-index: 15;

        & > div {
          position: absolute;
        }
      }
      &-result {
        position: fixed;
        bottom: 80px;
        left: 375px;
        // top: -60px;
        // left: 0px;
        width: 330px;
        background: #ffffff;
        border: 1px solid #e1e1e1;
        box-shadow: 0px 0px 40px 7px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        padding: 10px 0;
        max-height: 350px;
        overflow-y: auto;
        z-index: 10000;

        .item {
          padding: 8px 14px;

          &-name {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #313131;
          }

          &-info {
            font-size: 12px;
            line-height: 24px;
            color: #888888;
            height: 24px;
            overflow: hidden;
          }
        }
      }
    }

    table#inventory_table {
      min-width: 1100px;

      tr {
        cursor: pointer;
        th {
          border-bottom: 1px solid #e1e1e1;
          border-top: 1px solid #e1e1e1;
          //border-top: none;
        }
        td {
          border-bottom: 1px solid #e1e1e1;
          border-left: none;
          border-right: none;
          border-top: none;
          border-bottom: 1px solid #e1e1e1;
        }
      }
    }

    .table-docs {
      min-height: calc(100%) !important;
      padding-bottom: 0 !important;
    }

    table.table {
      border-top: none;
    }
    .custom-control-label {
      cursor: pointer !important;
    }
    .custom-control-input {
      display: none !important;
    }
    .elm-calendar input {
      height: 31px !important;
    }

    .product-search {
      .b-search__wrapper {
        width: 250px !important;
      }
    }
  }

  .b-search {
    &__wrapper {
      & > div {
        position: absolute;
      }
    }
    &-result {
      .item {
        &-info {
          span {
            margin-right: 12px;
          }
        }

        &:hover {
          background: #efefef;
        }
      }
    }
  }

  .rotate {
    transform: rotate(360deg) !important;
  }

  .button-add-mass-product {
    margin-left: 380px;
    background-color: #ebe9e9 !important;
    height: 32px !important;
    width: 48px !important;
    border-radius: 2px !important;
    bottom: 5 !important;
  }

  .product-search-block {
    height: 27px;
    width: 450px;
  }
</style>
