var render = function render(){
  var _vm$product_list, _vm$product_list$find;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "b-table__wrapper"
  }, [_c('mass-add-product', {
    on: {
      "add_products": _vm.createSpec
    }
  }), _c('categories-load-modal', {
    attrs: {
      "included_categories": _vm.includedCategories
    },
    on: {
      "add-categories": _vm.addCategories
    }
  }), _c('result-modal', {
    attrs: {
      "extra": _vm.filter_params,
      "comment": _vm.comment,
      "items": _vm.sheet.items,
      "total": _vm.sheet.total,
      "loading_sheet": _vm.loading_sheet
    },
    on: {
      "fixed": _vm.makeFixed,
      "load_items": _vm.loadSheetItems,
      "change_comment": _vm.changeCommentFromRezultModal
    }
  }), _c('product-movement-modal', {
    attrs: {
      "product": (_vm$product_list = _vm.product_list) === null || _vm$product_list === void 0 ? void 0 : (_vm$product_list$find = _vm$product_list.find(function (el) {
        return el.id === _vm.selected[0];
      })) === null || _vm$product_list$find === void 0 ? void 0 : _vm$product_list$find.product
    }
  }), _c('div', {
    staticClass: "b-table__filter d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "b-toggle-button"
  }, [_c('e-button', {
    staticClass: "btn-filters cursor",
    attrs: {
      "size": "m"
    },
    on: {
      "click": _vm.changeVisibleParams
    }
  }, [_vm._v(" Параметры документа "), _c('img', {
    staticClass: "ml-3",
    class: {
      rotate: _vm.visibleParams
    },
    attrs: {
      "src": "/img/icons/arrow_up.svg",
      "alt": ""
    }
  })])], 1), _c('div', {
    staticClass: "b-action-buttons d-flex"
  }, [!_vm.filter_params.fixed ? _c('e-button', {
    attrs: {
      "size": "m",
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.back
    }
  }, [_vm._v(" Сохранить как черновик ")]) : _vm._e(), _c('e-button', {
    staticClass: "ml-3",
    attrs: {
      "size": "m",
      "disabled": _vm.loadingFixed || _vm.is_request_update,
      "loading": _vm.loadingFixed || _vm.is_request_update,
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.handlerDocument(_vm.filter_params.fixed);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.filter_params.fixed ? 'Редактировать' : 'Продолжить') + " ")]), _c('b-dropdown', {
    staticClass: "ml-3",
    attrs: {
      "no-caret": "",
      "variant": "none"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "btn-more"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/more.svg",
            "alt": ""
          }
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    on: {
      "click": _vm.printList
    }
  }, [_vm._v(" Печать: Форма “Список для Просчета” ")]), _c('b-dropdown-item', {
    on: {
      "click": _vm.printSheet
    }
  }, [_vm._v(" Печать: Форма “Сличительная Ведомость“ ")])], 1)], 1)]), _c('e-collapse', {
    attrs: {
      "id": "filters-col",
      "show-button": false
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('inventory-filters', {
          ref: "inventory_filters",
          attrs: {
            "fixed": _vm.filter_params.fixed,
            "filter_params": _vm.filter_params,
            "error_storage": _vm.errorStorage,
            "is_entity_error": _vm.entityError
          },
          on: {
            "change_date": _vm.changeDate,
            "change_accounting_period": _vm.changeAccountingPeriod,
            "change_storage": _vm.changeStorage,
            "change_comment": _vm.changeComment,
            "change_type": _vm.changeType,
            "change_entity": _vm.changeEntity,
            "change_price_type": _vm.changePriceType,
            "set_error_storage": _vm.setErrorStorage,
            "set_error_entity": _vm.setErrorEntity
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.visibleParams,
      callback: function ($$v) {
        _vm.visibleParams = $$v;
      },
      expression: "visibleParams"
    }
  }), _c('div', {
    staticClass: "b-table__content"
  }, [_c('div', {
    staticClass: "table-filter"
  }, [_c('b-form-input', {
    staticClass: "filter-search",
    attrs: {
      "type": "text",
      "placeholder": "Поиск",
      "is-keyup": true
    },
    on: {
      "input": _vm.filterSearch
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "margin": "0 16px",
      "padding": "0 12px",
      "background": "#efefef",
      "height": "32px",
      "align-items": "center",
      "border-radius": "2px",
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        $event.preventDefault();
        _vm.table.diff_only = !_vm.table.diff_only;

        _vm.updateProductTable();
      }
    }
  }, [_c('b-checkbox', {
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.table.diff_only,
      callback: function ($$v) {
        _vm.$set(_vm.table, "diff_only", $$v);
      },
      expression: "table.diff_only"
    }
  }), _c('span', {
    staticStyle: {
      "color": "#313131",
      "font-size": "14px"
    }
  }, [_vm._v("Показывать только с расхождением")])], 1), _vm.currentType === 'categories' ? _c('b-button', {
    on: {
      "click": _vm.showCategories
    }
  }, [_vm._v(" Загрузка групп товаров ")]) : _vm._e(), _c('b-dropdown', {
    staticClass: "ml-3",
    staticStyle: {
      "height": "32px",
      "width": "32px"
    },
    attrs: {
      "no-caret": "",
      "variant": "none"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "btn-more"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/settings.svg",
            "alt": ""
          }
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.sort-table-modal",
      modifiers: {
        "sort-table-modal": true
      }
    }]
  }, [_vm._v(" Изменить столбцы ")])], 1)], 1), _c('div', {
    staticClass: "table-inner"
  }, [_c('resizable-table', {
    attrs: {
      "resizable": false,
      "foot_row": false,
      "table_name": "invspec-docs",
      "default_fields": _vm.fields,
      "items": _vm.product_list,
      "busy": _vm.tableIsBusy,
      "inside_card": false,
      "empty_text": "Ничего не найдено"
    },
    on: {
      "scroll-down": _vm.scrollDown,
      "scroll-up": _vm.scrollUp,
      "sort_change": _vm.sortChange
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center my-2",
          staticStyle: {
            "width": "100%"
          }
        }, [_c('b-spinner', {
          attrs: {
            "variant": "primary",
            "label": "Загрузка..."
          }
        }), _c('div', {
          staticClass: "mt-2"
        }, [_c('strong', [_vm._v("Загрузка...")])])], 1)];
      },
      proxy: true
    }, {
      key: "head_id",
      fn: function () {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.select_all,
            callback: function ($$v) {
              _vm.select_all = $$v;
            },
            expression: "select_all"
          }
        })];
      },
      proxy: true
    }, {
      key: "body_id",
      fn: function (data) {
        return [_c('b-form-checkbox', {
          staticClass: "cursor",
          attrs: {
            "value": data.item.id
          },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v;
            },
            expression: "selected"
          }
        })];
      }
    }, {
      key: "body_name",
      fn: function (data) {
        return [_c('product-spec-info', {
          attrs: {
            "product": data.item.product
          }
        })];
      }
    }, {
      key: "body_measurement",
      fn: function (data) {
        var _data$item$product, _data$item$product$me;

        return [_c('span', [_vm._v(_vm._s((_data$item$product = data.item.product) === null || _data$item$product === void 0 ? void 0 : (_data$item$product$me = _data$item$product.measurement) === null || _data$item$product$me === void 0 ? void 0 : _data$item$product$me.name))])];
      }
    }, {
      key: "body_remainder",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(+data.item.remainder.toFixed(3) || 0) + " ")];
      }
    }, {
      key: "body_delta",
      fn: function (data) {
        return [_c('div', {
          staticClass: "count"
        }, [_c('table-row-input', {
          ref: data.item.id + 'delta',
          attrs: {
            "id": "tooltip-target-1",
            "value": data.item.delta,
            "fixed": _vm.filter_params.fixed,
            "input_id": data.item.id + 'delta',
            "digits": 3
          },
          on: {
            "handle-input": function (val) {
              return _vm.setDelta(val, data.item, true);
            },
            "input": function (val) {
              return _vm.setDelta(val, data.item);
            },
            "on-focus": _vm.centeredScroll
          }
        })], 1)];
      }
    }, {
      key: "body_status",
      fn: function (data) {
        return [_c('b-badge', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v(_vm._s(data.item.status))])];
      }
    }, {
      key: "body_diff",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(+data.item.diff.toFixed(3) || 0) + " ")];
      }
    }, {
      key: "body_diff_sum",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(+item.diff_sum.toFixed(3) || 0) + " ")];
      }
    }, {
      key: "body_manager",
      fn: function (data) {
        var _data$item$created_by, _data$item$created_by2;

        return [_vm._v(" " + _vm._s((_data$item$created_by = data.item.created_by) === null || _data$item$created_by === void 0 ? void 0 : _data$item$created_by.last_name) + " " + _vm._s((_data$item$created_by2 = data.item.created_by) === null || _data$item$created_by2 === void 0 ? void 0 : _data$item$created_by2.first_name) + " ")];
      }
    }, {
      key: "body_empty",
      fn: function (scope) {
        return [_c('p', {
          staticClass: "center mt-3"
        }, [_vm._v(_vm._s(scope.emptyFilteredText))])];
      }
    }, {
      key: "foot_product",
      fn: function () {
        return [_c('span')];
      },
      proxy: true
    }, {
      key: "foot_order",
      fn: function () {
        return [_c('span')];
      },
      proxy: true
    }, {
      key: "foot_name",
      fn: function () {
        return [_c('span')];
      },
      proxy: true
    }, {
      key: "foot_balance",
      fn: function () {
        var _vm$table_total$delta;

        return [_c('span', {
          staticClass: "foot-col"
        }, [_vm._v("Итого: " + _vm._s(((_vm$table_total$delta = _vm.table_total.deltaTotal) === null || _vm$table_total$delta === void 0 ? void 0 : _vm$table_total$delta.toLocaleString().split(',')[0]) || 0))])];
      },
      proxy: true
    }, {
      key: "foot_delta",
      fn: function () {
        var _vm$table_total$factT;

        return [_c('span', {
          staticStyle: {
            "padding-right": "22px"
          }
        }, [_vm._v("Итого: " + _vm._s(((_vm$table_total$factT = _vm.table_total.factTotal) === null || _vm$table_total$factT === void 0 ? void 0 : _vm$table_total$factT.toLocaleString()) || 0))])];
      },
      proxy: true
    }, {
      key: "foot_measurement",
      fn: function () {
        return [_c('span')];
      },
      proxy: true
    }, {
      key: "foot_difference",
      fn: function () {
        var _vm$table_total$diffT;

        return [_c('span', [_vm._v(_vm._s(((_vm$table_total$diffT = _vm.table_total.diffTotal) === null || _vm$table_total$diffT === void 0 ? void 0 : _vm$table_total$diffT.toLocaleString().split(',')[0]) || 0))])];
      },
      proxy: true
    }, {
      key: "foot_difference_sum",
      fn: function () {
        return [_c('span', [_vm._v(_vm._s(_vm.formatPrice(_vm.table_total.sumTotal).toLocaleString()) + " ₽")])];
      },
      proxy: true
    }, {
      key: "foot_manager",
      fn: function () {
        return [_c('span')];
      },
      proxy: true
    }, {
      key: "footer-block",
      fn: function () {
        var _vm$table_total$delta2, _vm$table_total$factT2, _vm$table_total$diffT2;

        return [_c('div', {
          staticClass: "table-footer incoming-table-footer align-middle d-flex align-items-center p-3"
        }, [_c('div', {
          staticClass: "mr-auto product-search-block"
        }, [!_vm.filter_params.fixed ? _c('product-search', {
          staticClass: "ml-2 product-search",
          staticStyle: {
            "width": "250px"
          },
          attrs: {
            "document_head": _vm.extra_params.id,
            "document": _vm.extra_params,
            "document_type": "iventarisation"
          },
          on: {
            "select_product": _vm.select_product
          }
        }) : _vm._e(), !_vm.filter_params.fixed ? _c('e-button', {
          staticClass: "button-add-mass-product",
          on: {
            "click": _vm.showMassAddProduct
          }
        }, [_c('img', {
          staticStyle: {
            "position": "absolute"
          },
          attrs: {
            "src": "/img/icons/mass-add.svg",
            "alt": "addProducts"
          }
        })]) : _vm._e()], 1), _c('div', {
          staticClass: "footer-data mr-5"
        }, [_vm._v(" Остаток учет: " + _vm._s(((_vm$table_total$delta2 = _vm.table_total.deltaTotal) === null || _vm$table_total$delta2 === void 0 ? void 0 : _vm$table_total$delta2.toLocaleString()) || 0) + " ")]), _c('div', {
          staticClass: "footer-data mr-5"
        }, [_vm._v("Остаток факт: " + _vm._s(((_vm$table_total$factT2 = _vm.table_total.factTotal) === null || _vm$table_total$factT2 === void 0 ? void 0 : _vm$table_total$factT2.toLocaleString()) || 0))]), _c('div', {
          staticClass: "footer-data mr-5"
        }, [_vm._v("Разница кол-во: " + _vm._s(((_vm$table_total$diffT2 = _vm.table_total.diffTotal) === null || _vm$table_total$diffT2 === void 0 ? void 0 : _vm$table_total$diffT2.toLocaleString()) || 0))]), _c('div', {
          staticClass: "footer-data"
        }, [_vm._v("Разница сумма: " + _vm._s(_vm.formatPrice(_vm.table_total.sumTotal).toLocaleString()) + " ₽")])])];
      },
      proxy: true
    }])
  })], 1), _c('table-navbar', {
    staticStyle: {
      "margin": "-55px 0 0 !important"
    },
    attrs: {
      "items": _vm.selected,
      "clear_selected": _vm.clearSelected,
      "items_name": "inventory"
    },
    on: {
      "remove_items": _vm.removeProducts,
      "show_move": _vm.showMove
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }