<template>
  <div
    class="d-flex justify-content-between align-items-center product-info"
    :class="{ normal: !product.hide }"
    @click="openProduct"
  >
    <div
      class="text-left"
      style="width: 85%"
    >
      {{ product.name }}
      <div class="b-name article text-left">
        {{ info }}
      </div>
    </div>
  </div>
</template>

<script>
  import { ProductModel } from '@/models/product.model'

  export default {
    name: 'ProductSpecInfo',
    props: {
      product: ProductModel
    },
    computed: {
      info() {
        if (this.product.hide) {
          let str = ''
          if (this.product?.productExternalId?.[0]?.egaisParams?.type)
            str += 'Вид: ' + this.product?.productExternalId?.[0]?.egaisParams?.type + ' '
          if (this.product?.productExternalId?.[0]?.egaisParams?.strength)
            str += 'крепкость: ' + this.product?.productExternalId?.[0]?.egaisParams?.strength + ' '
          if (this.product?.productExternalId?.[0]?.egaisParams?.volume)
            str += 'крепкость: ' + this.product?.productExternalId?.[0]?.egaisParams?.volume + ' '
          if (this.product?.ext_system?.other?.[0]?.code) str += this.product?.ext_system?.other?.[0]?.code
          return str
        }
        return this.product.articul || 'Не указан'
      }
    },
    methods: {
      openProduct() {
        if (!this.product.hide)
          this.$router.push({
            name: 'products.edit',
            params: { name: this.product.name, id: this.product.id }
          })
      }
    }
  }
</script>

<style scoped lang="scss">
  .b-name {
    &.article {
      font-size: 12px;
      line-height: 24px;
      color: #888888;
      margin-top: 5px;
    }
  }
  .product-info.normal:hover {
    cursor: pointer;
    color: #00a3ff;
  }
</style>
