var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "categories-container"
  }, [_c('div', {
    staticClass: "header-groups",
    on: {
      "click": _vm.showAddGroup
    }
  }, [_c('h5', [_vm._v("Группы товаров")]), _vm._m(0)]), _c('div', {
    staticClass: "categories"
  }, [_vm.block_add_group ? [_c('div', {
    staticClass: "p-2"
  }, [_c('add-group', {
    on: {
      "refetchData": _vm.refetchData,
      "closeAddGroup": _vm.closeAddGroup,
      "groupAddedSuccessfully": _vm.groupAddedSuccessfully
    }
  })], 1)] : _vm._e(), _c('div', {
    staticClass: "categories-block"
  }, [_vm.menu_is_loading ? _c('div', {
    staticClass: "products__menu-no-goups center"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary"
    }
  })], 1) : _vm._e(), _vm.menu_arr && _vm.menu_arr.length > 0 && !_vm.menu_is_loading ? _c('div', {
    staticClass: "w-100"
  }, [_c('menu-item', {
    attrs: {
      "close_add_group": _vm.closeAddGroup,
      "items": _vm.menu_arr,
      "open_group": _vm.openGroup,
      "update_menu": _vm.groupAddedSuccessfully,
      "modal_remove": _vm.openModalRemove,
      "current_ids": _vm.current_ids,
      "selected_categories": _vm.getSelectedCategories,
      "hide_control": "",
      "hide_extra_charge": ""
    },
    on: {
      "close_add_form": _vm.closeAddGroup,
      "selected_group": _vm.selectedGroup,
      "select_group": _vm.selectGroup
    }
  })], 1) : _vm._e()])], 2), _c('remove-modal', {
    attrs: {
      "group": _vm.group
    },
    on: {
      "remove-item": _vm.removeItem
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-groups__button"
  }, [_c('img', {
    staticClass: "quantity-box__button-up",
    attrs: {
      "src": "/img/icons/incoming/Plus.svg",
      "alt": "up"
    }
  })]);

}]

export { render, staticRenderFns }