<template>
  <div class="categories-container">
    <div
      class="header-groups"
      @click="showAddGroup"
    >
      <h5>Группы товаров</h5>
      <div class="header-groups__button">
        <img
          class="quantity-box__button-up"
          src="/img/icons/incoming/Plus.svg"
          alt="up"
        />
      </div>
    </div>
    <div class="categories">
      <template v-if="block_add_group">
        <div class="p-2">
          <add-group
            @refetchData="refetchData"
            @closeAddGroup="closeAddGroup"
            @groupAddedSuccessfully="groupAddedSuccessfully"
          />
        </div>
      </template>

      <div class="categories-block">
        <div
          v-if="menu_is_loading"
          class="products__menu-no-goups center"
        >
          <b-spinner variant="primary" />
        </div>
        <div
          v-if="menu_arr && menu_arr.length > 0 && !menu_is_loading"
          class="w-100"
        >
          <menu-item
            :close_add_group="closeAddGroup"
            :items="menu_arr"
            :open_group="openGroup"
            :update_menu="groupAddedSuccessfully"
            :modal_remove="openModalRemove"
            :current_ids="current_ids"
            :selected_categories="getSelectedCategories"
            hide_control
            hide_extra_charge
            @close_add_form="closeAddGroup"
            @selected_group="selectedGroup"
            @select_group="selectGroup"
          />
        </div>
      </div>
    </div>
    <remove-modal
      :group="group"
      @remove-item="removeItem"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import MenuItem from '../../../../products/components/menu/MenuItem.vue'
  import RemoveModal from '@/views/products/modal/RemoveModal'
  import AddGroup from '../../../../products/components/menu/AddGroup.vue'

  export default {
    components: {
      RemoveModal,
      MenuItem,
      AddGroup
    },
    apollo: {

      ReadCategoryByPath: {
        query: require('../../../../products/gql/getCategoryPath.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            path: this.current_category_path?.filter((el) => el) ?? []
          }
        },
        async result({ data }) {
          if (data) {
            if (data?.ReadCategoryByPath?.[0] && !this.current_category?.id) {
              this.setCurrentCategory(data?.ReadCategoryByPath?.[0])
            }
            this.menu_arr = await this.updateCategories(data?.ReadCategoryByPath ?? [])
            this.menu_is_loading = false
          }
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    data: () => ({
      menu_is_loading: true,
      modal_show: false,
      block_add_group: false,
      child_is_load: false,
      menu_group: {
        name: ''
      },
      menu_arr: [],
      current_group_id: '',
      group: {
        name: '',
        id: ''
      },
      remove_group_is_loading: false,
      isCreatedNewGroup: false
    }),

    computed: {
      ...mapGetters({
        menuIsVisible: 'products/getMenuVisibleStatus',
        current_category_path: 'products/getCurrentCategoryPath',
        current_category: 'products/getCurrentCategory',
        current_ids: 'products/getCurrentIds',
        currentBranch: 'settings/getCurrentBranch',
        getSelectedCategories: 'products/getSelectedCategories'
      })
    },

    created() {
      // this.$apollo.queries.Categories.refetch()
    },

    methods: {
      ...mapActions({
        setMenuVisibleStatus: 'products/setMenuVisibleStatus',
        setCurrentCategoryPath: 'products/setCurrentCategoryPath',
        setCurrentsIds: 'products/setCurrentIds',
        setCurrentCategoryMenuId: 'products/setCurrentCategoryMenuId',
        setCurrentCategory: 'products/setCurrentCategory',
        setAddgroupVisibleStatus: 'products/setAddgroupVisibleStatus',
        setSelectedCategories: 'products/setSelectedCategories'
      }),
      modal_add_group() {
        this.$refs.modal_add_group.show()
      },

      selectedGroup(category) {
        setTimeout(() => {
          if (!this.getSelectedCategories?.includes(category.id))
            this.setSelectedCategories([...this.getSelectedCategories, category.id])
          else this.setSelectedCategories(this.getSelectedCategories.filter((el) => el !== category.id))
        }, 0)
      },
      async selectGroup({ category, value, clickCheck }) {
        if (!clickCheck) return
        const CATEGORIES = ['level_one', 'level_two', 'level_three', 'level_four']
        const { data } = await this.$apollo.query({
          query: require('../../../../operational-processes/gql/Categories.graphql'),
          variables: {
            input: {
              [CATEGORIES[category.level]]: category.id
            }
          }
        })
        if (value) {
          if (data.Categories)
            this.setSelectedCategories([...this.getSelectedCategories, ...data.Categories.map((el) => el.id)])
        } else
          this.setSelectedCategories(
            this.getSelectedCategories.filter((el) => !data.Categories?.some((category) => category.id === el))
          )
      },

      closeAddGroup() {
        this.block_add_group = false
      },

      showAddGroup() {
        this.block_add_group = true
        this.setAddgroupVisibleStatus(true)
      },

      refetchData() {},

      groupAddedSuccessfully() {
        this.current_group_id = this.current_category.id
        // this.isCreatedNewGroup = true
        this.closeAddGroup()
        this.$apollo.queries.Categories.refetch()
      },

      recursionMenu(items, childs) {
        const new_items = [...items]

        return new_items?.map((item) => {
          if (item.id === childs.id) {
            item.child_groups = childs.child_groups?.map((child) => {
              if (child.child_groups) child.child_groups = null

              return child
            })
          } else if (item.child_groups) {
            item.child_groups = this.recursionMenu(item.child_groups, childs)
          }

          return item
        })
      },

      getItemsIds(items, curId = null) {
        let result = []

        if (items?.id) {
          result.push(items.id)
          if (items.id !== curId && items?.child_groups?.length) {
            items.child_groups.forEach((t) => {
              result = [...result, ...this.getItemsIds(t)]
            })
          }
        }
        return result
      },

      getCurrentCategoriesPath(path, items) {
        let result = path
        if (!result) {
          result = items
        } else if (result?.child_groups?.length) {
          result.child_groups.forEach((t, index) => {
            if (t?.id === items?.id) {
              result.child_groups[index] = items
            } else {
              const chGroups = this.getCurrentCategoriesPath(t, items)
              if (chGroups?.child_groups === null) {
                delete chGroups.child_groups
              }
              result.child_groups[index] = chGroups
            }
          })
        }
        return result
      },

      addChild(items) {
        this.menu_arr = this.recursionMenu(this.menu_arr, items)

        if (this.getItemsIds(this.current_category_path).includes(items.id)) {
          this.setCurrentCategoryPath(this.getCurrentCategoriesPath(this.current_category_path, items))
        } else {
          this.setCurrentCategoryPath(items)
        }

        this.toggleChildLoading()
      },

      updateCategories(categories, current) {
        const updateCategories = [...categories]

        if (current) {
          updateCategories.forEach((category, index) => {
            if (category.id === current.id) updateCategories[index] = current
          })
        }

        return updateCategories
      },

      openGroup(item) {
        if (JSON.stringify(item?.path) == JSON.stringify(this.current_ids)) {
          this.isCreatedNewGroup = false
          this.setCurrentsIds((item?.path ?? []).slice(0, -1))
          return
        }
        if (item?.path?.every((el) => el === item.id) && this.current_ids.includes(item.id)) {
          this.setCurrentsIds([])
          return
        }

        this.setCurrentCategoryMenuId(item.id)
        this.setCurrentsIds(item?.path)
        this.setCurrentCategoryPath(item?.path)
        this.isCreatedNewGroup = false
        this.$apollo.queries.ReadCategoryByPath.refetch()
      },

      toggleChildLoading() {
        this.child_is_load = !this.child_is_load
      },

      hideProductMenu() {
        this.setMenuVisibleStatus(false)
      },

      openModalRemove(group) {
        this.group = group
        this.$bvModal.show('modal-remove-group')
      },

      removeItem() {
        this.$emit('refetch')
      },

      hideModalRemove() {
        this.$bvModal.hide('modal-remove-group')
      },

      removeItemMenu(items, id) {
        items.forEach((item, i) => {
          if (item.id === id) {
            items.splice(i, 1)
          } else if (item.child_groups) {
            this.removeItemMenu(item.child_groups, id)
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .categories-container {
    background: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    .header-groups {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      border-bottom: 1px solid #e1e1e1;

      &__button {
        border: solid 1px var(--text-placeholder-gray-300, #bcbcbc);
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      h5 {
        margin: 0;
        font-family: 'Gilroy-Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #313131;
      }
    }
    .categories {
      height: 385px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #bcbcbc;
      }
    }

    .categories-block {
      overflow: hidden;
    }
  }
  .menu-item {
    max-width: calc(100% - 15px);

    li {
      width: calc(100% - 15px) !important;
    }
  }
</style>
