<template>
  <section id="inventory-document">
    <!-- <breadcrumbs :extra="filter_params" /> -->
    <inventory-spec-table
      :fields="table.fields"
      @sync_filter_params="syncExtraParams"
    />
  </section>
</template>
<script>
  import InventorySpecTable from '@/views/operational-processes/components/inventory/InventorySpecTable'
  import * as R from 'ramda'
  import { mapActions } from 'vuex'

  export default {
    components: { InventorySpecTable },
    data: function () {
      return {
        filter_params: {
          fixed: false
        },
        table: {
          fields: [
            {
              key: 'id',
              label: '',
              width: 40,
              checked: true
            },
            {
              checked: true,
              key: 'order',
              label: '№',
              class: 'center',
              sortable: true,
              width: 50
            },
            {
              checked: true,
              key: 'name',
              label: 'Наименование товара',
              sortable: true,
              class: 'text-left',
              width: 329
            },

            {
              checked: true,
              key: 'remainder',
              label: 'Остаток учет',
              width: 182,
              sortable: true
            },
            {
              checked: true,
              key: 'delta',
              label: 'Остаток факт',
              width: 182,
              sortable: true
            },
            {
              checked: true,
              key: 'measurement',
              label: 'Ед. изм.',
              width: 90,
              sortable: true
            },
            {
              checked: true,
              key: 'diff',
              label: 'Разница кол-во',
              sortable: true,
              width: 184
            },
            {
              checked: true,
              key: 'diff_sum',
              label: 'Разница сумма',
              sortable: true,
              width: 184
            },
            {
              checked: true,
              key: 'manager',
              label: 'Сотрудник',
              width: 209,
              class: 'text-left',
              sortable: true
            }
          ]
        }
      }
    },
    beforeMount() {
      this.setBreadcrumbs({ is_go_back: true })
    },
    beforeDestroy() {
      this.setBreadcrumbs({})
    },
    methods: {
      ...mapActions({
        setBreadcrumbs: 'breadcrumbs/set_current'
      }),
      syncExtraParams(items) {
        this.filter_params = R.mergeLeft(items, this.filter_params)
        this.setBreadcrumbs({ ...this.filter_params, items, is_go_back: true })
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    #inventory-document {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .b-action-buttons {
      button {
        justify-content: center;
      }
    }

    table {
      tr {
        td {
          border-left: none;
          border-right: none;
        }
      }
    }

    .b-table {
      &__wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
      }

      &__content {
        flex: 1;
      }
    }

    .table-docs {
      margin-bottom: 0;
      min-height: calc(100% - 53px);
      height: calc(100% - 53px);
      padding-bottom: 53px;

      tr {
        th.b-checkbox {
          padding: 10px;
          width: 40px;

          .custom-checkbox {
            padding-left: 0;
            width: 16px;
            margin: 0 auto;

            .custom-control-label {
              &:before,
              &:after {
                left: -8px;
                box-shadow: none !important;
              }
            }
          }
        }
        td.b-checkbox {
          padding: 10px;
          width: 40px;

          .custom-checkbox {
            padding-left: 0;
            width: 16px;
            margin: 0 auto;

            .custom-control-label {
              &:before,
              &:after {
                left: -8px;
                box-shadow: none !important;
              }
            }
          }
        }
      }
    }

    .b-table__filter {
      .b-toggle-button {
        button {
          background: #e2e2e2;
          color: #313131;

          img {
            margin-right: 0;
            transition: all 0.2s;
            transform: rotate(180deg);
          }

          &.not-collapsed {
            img {
              transform: rotate(0);
            }
          }
        }
      }
    }

    .table-filter {
      align-items: center;
      button {
        background: #efefef;
        color: #313131;
      }
    }

    .table-inner {
      max-width: 100%;
      flex: 1;
      height: calc(100% - 64px);
      position: relative;

      table {
        // width: 2000px;

        tr {
          td {
            padding: 10px 16px;
            vertical-align: middle;
            text-align: center;
          }
        }

        caption {
          padding: 0;

          td {
            border-top: none;
          }
        }
      }
    }

    .b-search {
      &-product {
        padding: 10px 10px 10px 126px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }

      &__wrapper {
        position: relative;
        width: 321px;
      }

      &-result {
        position: absolute;
        z-index: 10;
        bottom: 45px;
        left: 0px;
        width: 330px;
        background: #ffffff;
        border: 1px solid #e1e1e1;
        box-shadow: 0px 0px 40px 7px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        padding: 10px 0;
        max-height: 460px;
        overflow-y: auto;

        .item {
          padding: 8px 14px;

          &-name {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #313131;
          }

          &-info {
            font-size: 12px;
            line-height: 24px;
            color: #888888;
            height: 24px;
            overflow: hidden;

            span {
              margin-right: 12px;
            }
          }

          &:hover {
            background: #efefef;
          }
        }
      }
    }

    .b-name {
      &.article {
        font-size: 12px;
        line-height: 24px;
        color: #888888;
        margin-top: 5px;
      }
    }

    .count {
      display: inline-block;

      .cursor {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          margin-left: 10px;
          opacity: 0;
        }
      }

      &:hover {
        background: #efefef;

        img {
          opacity: 1;
        }
      }
    }
  }

  #inventory-document {
    display: flex;
    flex-direction: column;
    height: 100%;

    .table-filter {
      button {
        &:hover {
          background: #efefef;
          color: #313131;
        }
      }
    }

    .b-name {
      &.article {
        font-size: 12px;
        line-height: 24px;
        color: #888888;
        margin-top: 5px;
      }
    }

    .count {
      &:hover {
        background: #efefef;
      }
    }
  }
</style>
