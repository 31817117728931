<template>
  <div class="params-body">
    <div class="card pb-0 pt-0">
      <b-form class="form">
        <b-row class="w-100">
          <b-col
            cols="3"
            class="mt-16"
          >
            <b-form-group
              label="Дата"
              label-for="work_period"
            >
              <div
                v-if="fixed"
                class="text_column"
              >
                {{ convertDate(form.date) }}
              </div>
              <template v-else>
                <date-picker
                  id="work_period"
                  v-model="form.date"
                  class="elm-calendar"
                  placeholder="Выберите дату"
                  :clearable="false"
                  format="dd.MM.yyyy"
                  :is-keyup="true"
                  @on-change="cangeDate"
                />
              </template>
            </b-form-group>
          </b-col>

          <b-col
            cols="3"
            class="mt-16"
          >
            <b-form-group
              label="Период учета остатка"
              label-for="accounting_period"
            >
              <div
                v-if="fixed"
                class="text_column"
              >
                {{ getAccountingPeriodName }}
              </div>
              <template v-else>
                <i-select
                  id="accounting-period"
                  :value="filter_params.accounting_period"

                  @on-select="changeAccountingPeriod"
                >
                  <i-option value="begin">Начало дня</i-option>
                  <i-option
                    v-for="item in accounting_period_list"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </i-option>
                </i-select>
              </template>
            </b-form-group>
          </b-col>

          <b-col
            cols="3"
            class="mt-16"
          >
            <b-form-group
              label="Место хранения"
              label-for="storage"
              :class="{ error: error_storage }"
            >
              <div
                v-if="fixed"
                class="text_column"
              >
                {{ form.storage.name }}
              </div>
              <template v-else>
                <i-select
                  id="storage"
                  v-model="form.storage.id"
                  filterable
                  @on-select="changeStorage"
                >
                  <i-option
                    v-for="item in storages_list"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </i-option>
                </i-select>
              </template>
            </b-form-group>
          </b-col>
          <b-col
            cols="3"
            class="mt-16"
          >
            <b-form-group
              label="Тип инвентаризации"
              label-for="type"
            >
              <template>
                <i-select
                  id="type"
                  v-model="form.type"
                  :disabled="fixed"
                  :is-keyup="true"
                  @on-change="changeType"
                >
                  <i-option
                    v-for="item in inventory_type_list"
                    :key="item.id"
                    :value="item.id"
                    :label="item.name"
                  >
                    <div style="display: flex; flex-direction: row; align-items: center; min-width: 100%">
                      <span>{{ item.name }}</span>
                      <img
                        v-b-tooltip.hover.right="item.tooltip"
                        class="ml-3"
                        style="height: 16px; width: 16px"
                        src="/img/icons/icon-info.svg"
                        alt=""
                      />
                    </div>
                  </i-option>
                </i-select>
              </template>
            </b-form-group>
          </b-col>
          <b-col
            cols="3"
            class="mt-16"
          >
            <b-form-group
              label="Цена учета"
              label-for="price"
            >
              <template v-if="fixed">{{ form.price_type.name }}</template>
              <i-select
                id="price"
                v-model="form.price_type"
                :disabled="fixed"
                @on-change="changePriceType"
              >
                <i-option
                  v-for="item in price_type_list"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </i-option>
              </i-select>
            </b-form-group>
          </b-col>
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0 mt-16"
          >
            <b-form-group
              label="Организация"
              label-for="entity"
              :class="{ error: is_entity_error }"
            >
              <template v-if="fixed">
                {{ filter_params.entity.name }}
              </template>
              <e-select
                v-else
                v-model="active_entity"
                selected_type="text"
                :error="is_entity_error"
                :options="entity_list"
                select_name="entity_select"
              >
                <template #custom_name="{ item }">
                  <div class="entity">
                    <p class="name">{{ item.name }}</p>
                    <div class="inn_kpp">
                      <p class="inn">ИНН: {{ item.inn ? item.inn : '-' }}</p>
                      <p class="kpp">КПП: {{ item.kpp ? item.kpp : '-' }}</p>
                    </div>
                  </div>
                </template>
              </e-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="w-100">
          <b-col>
            <div
              v-b-toggle.collapse-extraparams
              style="margin-bottom: 16px"
              class="btn-extra-param cursor"
            >
              <span class="isHide">Показать</span>
              <span class="isShow">Скрыть</span>
              дополнительные параметры
            </div>
            <b-collapse
              id="collapse-extraparams"
              class="row"
            >
              <b-col cols="4">
                <b-form-group
                  style="margin-bottom: 16px"
                  label="Комментарий"
                  label-for="comment"
                >
                  <b-form-textarea
                    id="comment"
                    v-model="form.comment"
                    rows="3"
                    :disabled="fixed"
                    max-rows="6"
                    :is-keyup="true"
                    @input="$emit('change_comment', form.comment)"
                  />
                </b-form-group>
              </b-col>
            </b-collapse>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
  import formatDate from '@/utils/formatDate'
  import { mapGetters } from 'vuex'
  import * as R from 'ramda'

  export default {
    props: {
      menu_list: {
        type: Array,
        default: () => []
      },

      filter_params: {
        type: Object,
        default: null
      },

      fixed: {
        type: Boolean,
        default: false
      },

      is_supplier_error: {
        type: Boolean,
        default: false
      },
      is_entity_error: {
        type: Boolean,
        default: false
      },
      error_storage: Boolean
    },

    apollo: {
      Storages: {
        query: require('../../gql/getStorages.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch.id
          }
        },
        result({ data }) {
          this.storages_list = R.pathOr([], ['Storages'], data)
          const storage_id = R.pathOr('', ['Storages', 0, 'id'], data)
          if ((this.form.storage.id == '' || !this.form.storage.id) && this.storages_list?.length === 1) {
            this.form.storage.id = storage_id
            this.changeStorage(storage_id)
          }
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    data: function () {
      return {
        accounting_period_list: [
          // {
          //   name: 'Начало дня',
          //   id: 'begin'
          // },
          {
            name: 'Конец дня',
            id: 'end'
          }
        ],

        inventory_type_list: [
          {
            name: 'По документу',
            tooltip: 'Система выполнит сверку остатка только по позициям в документе',
            id: 'document'
          },
          {
            name: 'По выбранным группам',
            tooltip: 'В документ добавятся товары выбранных групп с учетным остатком',
            id: 'categories'
          },
          {
            name: 'Полная',
            tooltip: 'В документ добавятся все товары с учетным остатком',
            id: 'full'
          }
        ],
        price_type_list: [
          {
            name: 'Закупочная',
            id: 'purchase'
          },
          {
            name: 'Розничная',
            id: 'retail'
          }
        ],
        active_entity: [],
        entity_list: [],
        storages_list: [],
        form: {
          date: new Date(),
          storage: {
            id: '',
            name: ''
          },
          entity: {},
          comment: '',
          price_type: 'purchase',
          type: 'document',
          accounting_period: 'begin'
        },
        isUpdatedFilterParams: false
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      }),

      getAccountingPeriodName() {
        if (this.form.accounting_period === 'begin') return 'Начало дня'
        else return 'Конец дня'
      }
    },
    watch: {
      filter_params(newVal) {
        if (!newVal || this.isUpdatedFilterParams) return false
        if (this.filter_params?.entity?.id) {
          this.active_entity = [this.filter_params.entity]
        }
        this.syncParams(newVal)
        this.isUpdatedFilterParams = true
      },
      active_entity(newVal) {
        if (this.filter_params.entity?.id === newVal[0]) return 0
        this.$emit('set_error_entity')
        this.form.entity = newVal[0]
        this.$emit('change_entity')
      }
    },

    mounted() {
      this.syncParams()
      this.entity_list = this.currentBranch?.entities ?? []
    },

    methods: {
      cangeDate() {
        this.$emit('change_date')
      },

      changeAccountingPeriod(newDate) {
        this.$emit('change_accounting_period', newDate)
      },

      changeStorage(id) {
        this.$emit('set_error_storage')
        this.storages_list.filter((item) => {
          if (item.id.includes(id)) this.form.storage = item
        })

        this.$nextTick(() => this.$emit('change_storage'))
      },
      changeType(id) {
        this.inventory_type_list.filter((item) => {
          if (item.id.includes(id)) this.form.type = item.id
        })

        this.$nextTick(() => this.$emit('change_type'))
      },
      changePriceType(id) {
        this.price_type_list.filter((item) => {
          if (item.id.includes(id)) this.form.price_type = item.id
        })

        this.$nextTick(() => this.$emit('change_price_type'))
      },
      syncParams(items) {
        this.form.date = items?.date ? new Date(items?.date) : new Date()
        this.form.comment = R.pathOr('', ['comment'], items)
        this.form.storage = R.mergeLeft(R.pathOr([], ['storage'], items), this.form.storage)
        this.form.entity = items?.entity ?? {}
        this.form.price_type = items?.price_type ?? 'purchase'
        this.form.type = items?.type ?? 'document'
        this.form.accounting_period = items?.accounting_period
      },
      convertDate(date) {
        if (date) return formatDate(new Date(date))
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .params-body {
      .btn-extra-param {
        border-bottom: 1px solid #313131;
        display: inline-block;

        .isShow {
          display: none;
        }

        &.not-collapsed {
          .isHide {
            display: none;
          }

          .isShow {
            display: inline;
          }
        }
      }

      textarea {
        overflow: hidden;
        resize: none;
      }
    }
  }

  .ms__dropdown-item.active {
    .name {
      color: #00a3ff;
    }
  }
  .mt-16 {
    margin-top: 16px;
  }
  .entity {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    p {
      margin-bottom: 0;
    }
    .name {
      display: flex;
      float: left;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #313131;
    }
    .inn_kpp {
      display: flex;
      .inn {
        margin-right: 5px;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #888888;
      }
    }
  }
  .card {
    margin-bottom: 0;
  }
  .params-body {
    padding-top: 16px;
    .form {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      gap: 15px 18px;

      ::v-deep .form-group {
        min-width: 150px;
        margin-bottom: 0;
      }
    }

    .btn-extra-param {
      border-bottom: 1px solid #313131;
      display: inline-block;

      .isShow {
        display: none;
      }

      &.not-collapsed {
        .isHide {
          display: none;
        }

        .isShow {
          display: inline;
        }
      }
    }

    // #collapse-extraparams {
    //   textarea {
    //     overflow: hidden;
    //     resize: none;
    //   }
    // }
  }
  .text_column {
    height: 30px;
    display: flex;
    align-items: center;
  }
</style>
