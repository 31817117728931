var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "inventory-document"
    }
  }, [_c('inventory-spec-table', {
    attrs: {
      "fields": _vm.table.fields
    },
    on: {
      "sync_filter_params": _vm.syncExtraParams
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }