var render = function render(){
  var _vm$product$articul, _vm$product2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "movement-modal"
    }
  }, [_c('b-modal', {
    attrs: {
      "id": "product-movement",
      "size": "xl",
      "centered": "",
      "header-class": "mb-0",
      "hide-footer": ""
    },
    on: {
      "show": _vm.addListener
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function (_ref) {
        var _vm$product;

        var close = _ref.close;
        return [_c('h5', {
          staticClass: "mb-1"
        }, [_vm._v(_vm._s((_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : _vm$product.name))]), _c('b-button', {
          staticClass: "close",
          on: {
            "click": function ($event) {
              return close();
            }
          }
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/modal_close.svg",
            "alt": ""
          }
        })])];
      }
    }])
  }, [_c('div', {
    staticClass: "movement-modal-inner"
  }, [_c('b-modal', {
    attrs: {
      "id": "accept-doc-modal",
      "centered": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function () {
        return [_c('h5', [_vm._v("Подтверждение перехода к документу")])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function (_ref2) {
        var cancel = _ref2.cancel;
        return [_c('div', {
          staticClass: "d-flex w-75"
        }, [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.openDocument
          }
        }, [_vm._v(" Продолжить ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": cancel
          }
        }, [_vm._v(" Отмена ")])], 1)];
      }
    }])
  }, [_vm._v(" Вы действительно хотите закрыть текущее окно и перейти к документу? ")]), _c('span', {
    staticClass: "mb-4"
  }, [_vm._v("Артикул:" + _vm._s((_vm$product$articul = (_vm$product2 = _vm.product) === null || _vm$product2 === void 0 ? void 0 : _vm$product2.articul) !== null && _vm$product$articul !== void 0 ? _vm$product$articul : '----'))]), _c('b-row', {
    staticClass: "w-75"
  }, [_c('b-col', {
    staticStyle: {
      "max-width": "fit-content"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Период отображения"
    }
  }, [_c('work-period', {
    staticStyle: {
      "width": "100%"
    },
    on: {
      "select": function ($event) {
        _vm.first = true;
      }
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Место хранения"
    }
  }, [_c('i-select', {
    attrs: {
      "id": "storage",
      "filterable": "",
      "transfer": ""
    },
    on: {
      "on-change": _vm.setStorage
    },
    model: {
      value: _vm.variables.storage,
      callback: function ($$v) {
        _vm.$set(_vm.variables, "storage", $$v);
      },
      expression: "variables.storage"
    }
  }, _vm._l(_vm.storage_list, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Операции"
    }
  }, [_c('i-select', {
    attrs: {
      "multiple": "",
      "transfer": "",
      "max-tag-count": 1,
      "max-tag-placeholder": function (val) {
        return '+ Еще ' + val;
      }
    },
    on: {
      "on-change": _vm.setOperations
    },
    model: {
      value: _vm.variables.operation,
      callback: function ($$v) {
        _vm.$set(_vm.variables, "operation", $$v);
      },
      expression: "variables.operation"
    }
  }, [_c('i-option-group', {
    attrs: {
      "label": "Документы прихода"
    }
  }, _vm._l(_vm.incoming_operations, function (item) {
    return _c('i-option', {
      key: item.key,
      attrs: {
        "value": item.key
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1), _c('i-option-group', {
    attrs: {
      "label": "Документы расхода"
    }
  }, _vm._l(_vm.outgoing_operations, function (item) {
    return _c('i-option', {
      key: item.key,
      attrs: {
        "value": item.key
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1), _c('i-option-group', {
    attrs: {
      "label": "Кассовая реализация"
    }
  }, _vm._l(_vm.cash_operations, function (item) {
    return _c('i-option', {
      key: item.key,
      attrs: {
        "value": item.key
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1), _c('i-option-group', {
    attrs: {
      "label": "Инвентаризация"
    }
  }, _vm._l(_vm.inventory_operation, function (item) {
    return _c('i-option', {
      key: item.key,
      attrs: {
        "value": item.key
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1), _c('i-option-group', {
    attrs: {
      "label": "Перемещение"
    }
  }, [_c('i-option', {
    attrs: {
      "value": "movement"
    }
  }, [_vm._v("Перемещение")])], 1), _c('i-option-group', {
    attrs: {
      "label": "Производство"
    }
  }, [_c('i-option', {
    attrs: {
      "value": "manufacture"
    }
  }, [_vm._v("Калькуляция")]), _c('i-option', {
    attrs: {
      "value": "reverse_manufacture"
    }
  }, [_vm._v("Перефасовка")])], 1)], 1)], 1)], 1)], 1), _c('resizable-table', {
    staticClass: "mb-3",
    staticStyle: {
      "height": "60vh"
    },
    attrs: {
      "table_name": "product-movement",
      "default_fields": _vm.fields,
      "items": _vm.movement
    },
    on: {
      "scroll-down": _vm.scrollDown,
      "row-click": _vm.goToDoc
    },
    scopedSlots: _vm._u([{
      key: "body_number",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-direction": "column"
          }
        }, [_c('span', [_vm._v(_vm._s(item.number))]), _c('span', {
          staticClass: "mt-1",
          staticStyle: {
            "color": "#888888",
            "font-size": "12px"
          }
        }, [_vm._v(_vm._s(_vm.operation_mapping[item.operation]))])])];
      }
    }, {
      key: "body_date",
      fn: function (_ref4) {
        var value = _ref4.value;
        return [_vm._v(" " + _vm._s(_vm.formatDate(value)) + " ")];
      }
    }, {
      key: "body_price",
      fn: function (_ref5) {
        var value = _ref5.value;
        return [_vm._v(" " + _vm._s(_vm.formatPrice(value)) + " ")];
      }
    }, {
      key: "body_sum",
      fn: function (_ref6) {
        var value = _ref6.value;
        return [_vm._v(" " + _vm._s(_vm.formatPrice(value)) + " ")];
      }
    }, {
      key: "body_user",
      fn: function (_ref7) {
        var value = _ref7.value;
        return [_vm._v(" " + _vm._s(_vm.formatUser(value)) + " ")];
      }
    }, {
      key: "body_fixed",
      fn: function (_ref8) {
        var value = _ref8.value;
        return [_vm._v(" " + _vm._s(value ? 'Проведен' : 'Разоприходован') + " ")];
      }
    }, {
      key: "body_storage",
      fn: function (_ref9) {
        var _item$supplier, _item$storage, _item$storage2, _item$targetStorage$n, _item$targetStorage, _item$supplier2, _item$storage3, _item$storage4;

        var item = _ref9.item;
        return [['income', 'return'].includes(item.operation) ? [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-direction": "column"
          }
        }, [_c('span', [_vm._v(_vm._s((_item$supplier = item.supplier) === null || _item$supplier === void 0 ? void 0 : _item$supplier.name))]), _c('div', [_c('b-icon', {
          staticClass: "mr-2",
          attrs: {
            "icon": "arrow-right"
          }
        }), _c('span', [_vm._v(_vm._s((_item$storage = item.storage) === null || _item$storage === void 0 ? void 0 : _item$storage.name))])], 1)])] : item.operation === 'movement' || item.operation === 'purchase' ? [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-direction": "column"
          }
        }, [_c('span', [_vm._v(_vm._s((_item$storage2 = item.storage) === null || _item$storage2 === void 0 ? void 0 : _item$storage2.name))]), _c('div', [_c('b-icon', {
          staticClass: "mr-2",
          attrs: {
            "icon": "arrow-right"
          }
        }), _c('span', [_vm._v(_vm._s((_item$targetStorage$n = (_item$targetStorage = item.targetStorage) === null || _item$targetStorage === void 0 ? void 0 : _item$targetStorage.name) !== null && _item$targetStorage$n !== void 0 ? _item$targetStorage$n : 'Покупатель'))])], 1)])] : item.operation === 'return' ? [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-direction": "column"
          }
        }, [_c('div', [_c('b-icon', {
          staticClass: "mr-2",
          attrs: {
            "icon": "arrow-right"
          }
        }), _c('span', [_vm._v(_vm._s((_item$supplier2 = item.supplier) === null || _item$supplier2 === void 0 ? void 0 : _item$supplier2.name))])], 1), _c('span', [_vm._v(_vm._s((_item$storage3 = item.storage) === null || _item$storage3 === void 0 ? void 0 : _item$storage3.name))])])] : [_vm._v(" " + _vm._s((_item$storage4 = item.storage) === null || _item$storage4 === void 0 ? void 0 : _item$storage4.name) + " ")]];
      }
    }, {
      key: "body_remainder",
      fn: function (_ref10) {
        var _item$remainder, _item$delta;

        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(+((_item$remainder = item.remainder) === null || _item$remainder === void 0 ? void 0 : _item$remainder.toFixed(3))) + " "), _c('span', {
          class: {
            error: item.delta < 0,
            success: item.delta >= 0
          }
        }, [_vm._v("(" + _vm._s(item.delta > 0 ? '+' : '') + _vm._s(+((_item$delta = item.delta) === null || _item$delta === void 0 ? void 0 : _item$delta.toFixed(3))) + ")")])];
      }
    }])
  }), _c('div', {
    staticClass: "mb-3 total-info w-75"
  }, [_c('span', {
    staticStyle: {
      "flex": "1"
    }
  }, [_vm._v("Остаток на начало периода: "), _c('span', {
    class: {
      error: _vm.remainder.begin_remainder < 0,
      success: _vm.remainder.begin_remainder > 0
    }
  }, [_vm._v(_vm._s(_vm.remainder.begin_remainder))])]), _c('span', {
    staticStyle: {
      "flex": "1"
    }
  }, [_vm._v("Остаток на конец периода: "), _c('span', {
    class: {
      error: _vm.remainder.end_remainder < 0,
      success: _vm.remainder.end_remainder > 0
    }
  }, [_vm._v(_vm._s(_vm.remainder.end_remainder))])]), _c('span', {
    staticStyle: {
      "flex": "1"
    }
  }, [_vm._v("Текущий остаток: "), _c('span', {
    class: {
      error: _vm.getRemainder < 0,
      success: _vm.getRemainder > 0
    }
  }, [_vm._v(_vm._s(_vm.getRemainder))])])])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }