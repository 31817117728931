<template>
  <b-modal
    id="mass_add_product"
    centered
    hide-footer
    @hide="hideModal"
  >
    <template #modal-header>
      <div class="header-container">
        <div class="title-block d-flex justify-content-between w-100">
          <h5 class="title">Добавить новый товар</h5>
          <b-button
            class="close"
            @click="hideModal()"
          >
            <img
              src="/img/icons/modal_close.svg"
              alt=""
            />
          </b-button>
        </div>
        <div
          v-if="showInfo"
          class="info-container"
        >
          <div class="d-flex align-items-center">
            <img
              src="/img/icons/info-blue.svg"
              alt="info"
            />
            <p>Чтобы добавить товар, нажимайте на чекбоксы рядом с группой или товаром</p>
          </div>
          <img
            class="close-help"
            src="/img/icons/blue-close.svg"
            alt="close"
            @click="closeInfo"
          />
        </div>
      </div>
    </template>
    <div class="modal-body">
      <div class="categories-block">
        <categories-block />
        <div class="footer-buttons">
          <e-button
            size="m"
            variant="primary"
            @click="addSelected"
          >
            Добавить
            <div
              v-if="getSelectedProducts.length !== 0"
              class="footer-buttons__quantity-products"
            >
              {{ getSelectedProducts.length }}
            </div>
          </e-button>

          <e-button
            size="m"
            variant="outline-primary"
            @click="hideModal"
            >Отмена</e-button
          >
        </div>
      </div>
      <mass-add-table @addSelected="addSelected" />
    </div>
  </b-modal>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import CategoriesBlock from '@/views/operational-processes/components/incoming/modal/CategoriesBlock'
  import MassAddTable from '@/views/operational-processes/components/incoming/modal/MassAddTable'
  export default {
    name: 'MassAddProduct',
    components: { MassAddTable, CategoriesBlock },
    props: {},
    data() {
      return {
        selected: [],
        showInfo: true
      }
    },
    computed: {
      ...mapGetters({
        current_category_path: 'products/getCurrentCategoryPath',
        getSelectedProducts: 'products/getSelectedProducts',
        getQuantityProducts: 'products/getQuantityProducts'
      }),

      getSumQuantityProducts() {
        let result = 0
        this.getQuantityProducts.forEach((el) => (result += el.quantity))
        return result
      }
    },
    beforeMount() {
      if (localStorage.getItem('hideInfoMassAdd')) {
        this.showInfo = false
      }
      this.setSelectedProducts([])
      this.setSelectedCategories([])
      this.setExcludedCategories([])
      this.setExcludedProducts([])
    },
    methods: {
      ...mapActions({
        setSelectedProducts: 'products/setSelectedProducts',
        setSelectedCategories: 'products/setSelectedCategories',
        setExcludedCategories: 'products/setExcludedCategories',
        setExcludedProducts: 'products/setExcludedProducts'
      }),
      show() {},
      addSelected() {
        this.$emit('add_products', this.getSelectedProducts)
        this.hideModal()
      },
      hideModal() {
        this.$bvModal.hide('mass_add_product')
        this.setSelectedProducts([])
        this.setSelectedCategories([])
        this.setExcludedCategories([])
        this.setExcludedProducts([])
      },
      closeInfo() {
        localStorage.setItem('hideInfoMassAdd', 'true')
        this.showInfo = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .modal-dialog {
    // max-width: 1064px !important;
    min-width: 90%;
    min-height: 90%;
  }
  ::v-deep .modal-header {
    margin: 0;
  }
  .header-container {
    width: 100%;
    .title-block {
      margin-bottom: 16px;
      .title {
        margin: 0;
        font-family: 'Gilroy-Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #313131;
      }
    }
    .info-container {
      margin-bottom: 22px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px;
      width: 100%;
      background: #eef9ff;
      border: 1px solid #99daff;
      border-radius: 2px;
      .close-help {
        cursor: pointer;
      }
      p {
        margin: 0 0 0 13px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #313131;
      }
    }
  }
  .modal-body {
    padding: 0;
    width: 100%;
    height: 520px;
    // height: 600px;
    display: flex;
    .categories-block {
      // width: 310px;
      width: 25%;
      margin-right: 16px;
    }
    // .table-block {
    //   display: flex;
    //   flex-direction: column;
    //   flex: 1;
    //   padding-bottom: 5px;
    //   overflow: hidden;
    // }
  }
  .footer-buttons {
    display: flex;
    width: 100%;
    margin-top: 32px;
    padding-bottom: 22px;
    button {
      width: fit-content !important;
      &:first-child {
        margin-right: 8px;
      }
    }

    &__quantity-products {
      border-radius: 100px;
      background: #fff;
      color: var(--text-system-blue-400, #00a3ff);
      text-align: center;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      margin-left: 6px;
      padding: 0px 7px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>
