var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "inventory-result",
      "size": "xl",
      "hide-footer": "",
      "hide-header": "",
      "centered": "",
      "body-class": "p-0 overflow-body"
    }
  }, [_c('breadcrumbs', {
    staticStyle: {
      "margin-left": "16px"
    },
    attrs: {
      "is_inventory_result": true,
      "extra": _vm.extra
    }
  }), _c('b-form-group', {
    staticClass: "w-25 ml-3",
    attrs: {
      "label": "Комментарий",
      "label-for": "comment"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "comment",
      "value": _vm.comment === null ? _vm.extra.comment : _vm.comment,
      "rows": "3",
      "max-rows": "6",
      "is-keyup": true
    },
    on: {
      "input": _vm.changeComment
    }
  })], 1), _c('b-card', {
    staticStyle: {
      "margin-bottom": "0 !important",
      "height": "55vh"
    },
    attrs: {
      "body-class": "card-decoration p-0 overflow-body"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('b-table', {
    attrs: {
      "show-empty": "",
      "fields": _vm.table.fields,
      "items": _vm.items,
      "borderless": true,
      "responsive": "",
      "empty-filtered-text": "Нет товаров",
      "details-td-class": "p-0",
      "busy": _vm.loading_sheet
    },
    on: {
      "row-clicked": _vm.getByCategory
    },
    nativeOn: {
      "scroll": function ($event) {
        return _vm.onScroll.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center pb-5"
        }, [_c('b-spinner', {
          attrs: {
            "variant": "primary",
            "label": "Загрузка..."
          }
        }), _c('div', [_c('strong', [_vm._v("Загрузка...")])])], 1)];
      },
      proxy: true
    }, {
      key: "empty",
      fn: function (scope) {
        return [_c('p', {
          staticClass: "center mt-3"
        }, [_vm._v(_vm._s(scope.emptyFilteredText))])];
      }
    }, _vm.items.length ? {
      key: "top-row",
      fn: function () {
        return [_c('td', {
          staticClass: "text-left"
        }, [_c('b', [_vm._v("Итого")])]), _c('td', {
          staticClass: "text-right"
        }, [_c('b', [_vm._v(_vm._s(_vm.formatNumbers(_vm.total.delta_total)))])]), _c('td', {
          staticClass: "text-right"
        }, [_c('b', [_vm._v(_vm._s(_vm.formatNumbers(_vm.total.fact_total)))])]), _c('td', {
          staticClass: "text-right"
        }, [_c('b', [_vm._v(_vm._s(_vm.formatNumbers(_vm.total.difference_total)))])]), _c('td', {
          staticClass: "text-right"
        }, [_c('b', [_vm._v(_vm._s(_vm.formatNumbers(_vm.total.difference_sum_total)))])])];
      },
      proxy: true
    } : null, {
      key: "cell(delta_category)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.formatNumbers(item.delta_category)) + " ")];
      }
    }, {
      key: "cell(fact_category)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.formatNumbers(item.fact_category)) + " ")];
      }
    }, {
      key: "cell(difference_category)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.formatNumbers(item.difference_category)) + " ")];
      }
    }, {
      key: "cell(difference_sum_category)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(_vm.formatNumbers(item.difference_sum_category)) + " ")];
      }
    }, {
      key: "row-details",
      fn: function (_ref5) {
        var item = _ref5.item;
        return _vm._l(item.products, function (row, index) {
          return _c('b-tr', {
            key: index,
            staticClass: "p-md-3",
            staticStyle: {
              "display": "flex",
              "flex-direction": "row",
              "padding-right": "0 !important"
            }
          }, [_c('div', {
            staticStyle: {
              "padding-left": "40px",
              "display": "flex",
              "flex": "1",
              "flex-direction": "column"
            }
          }, [_c('span', [_vm._v(_vm._s(row.product.name))])]), _c('b-col', {
            staticClass: "table-numeric"
          }, [_vm._v(" " + _vm._s(_vm.formatNumbers(row.delta)) + " ")]), _c('b-col', {
            staticClass: "table-numeric"
          }, [_vm._v(_vm._s(_vm.formatNumbers(row.fact)))]), _c('b-col', {
            staticClass: "table-numeric"
          }, [_vm._v(_vm._s(_vm.formatNumbers(row.diff)))]), _c('b-col', {
            staticClass: "table-numeric"
          }, [_vm._v(" " + _vm._s(_vm.formatNumbers(row.diff_sum)) + " ")])], 1);
        });
      }
    }, {
      key: "cell(category)",
      fn: function (_ref6) {
        var _item$category;

        var item = _ref6.item;
        return [_c('span', {
          staticClass: "cursor"
        }, [_c('img', {
          staticStyle: {
            "margin-right": "18px",
            "transition": "250ms"
          },
          style: item._showDetails ? '' : 'transform: rotate(-90deg)',
          attrs: {
            "src": "/img/icons/menu_arrow_down.svg"
          }
        })]), _c('span', [_vm._v(_vm._s((_item$category = item.category) === null || _item$category === void 0 ? void 0 : _item$category.name))])];
      }
    }], null, true)
  })], 1)]), _c('div', {
    staticClass: "card-control p-3"
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-primary",
      "disabled": _vm.loading_sheet
    },
    on: {
      "click": _vm.printDoc
    }
  }, [_vm._v(" Печать сличительной ведомости ")]), _c('div', [_c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v(" Назад ")]), _c('b-button', {
    staticClass: "ml-2",
    attrs: {
      "variant": "primary",
      "disabled": _vm.loading_sheet
    },
    on: {
      "click": _vm.fixed
    }
  }, [_vm._v(" Провести ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }