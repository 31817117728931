var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "mass_add_product",
      "centered": "",
      "hide-footer": ""
    },
    on: {
      "hide": _vm.hideModal
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function () {
        return [_c('div', {
          staticClass: "header-container"
        }, [_c('div', {
          staticClass: "title-block d-flex justify-content-between w-100"
        }, [_c('h5', {
          staticClass: "title"
        }, [_vm._v("Добавить новый товар")]), _c('b-button', {
          staticClass: "close",
          on: {
            "click": function ($event) {
              return _vm.hideModal();
            }
          }
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/modal_close.svg",
            "alt": ""
          }
        })])], 1), _vm.showInfo ? _c('div', {
          staticClass: "info-container"
        }, [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/info-blue.svg",
            "alt": "info"
          }
        }), _c('p', [_vm._v("Чтобы добавить товар, нажимайте на чекбоксы рядом с группой или товаром")])]), _c('img', {
          staticClass: "close-help",
          attrs: {
            "src": "/img/icons/blue-close.svg",
            "alt": "close"
          },
          on: {
            "click": _vm.closeInfo
          }
        })]) : _vm._e()])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "categories-block"
  }, [_c('categories-block'), _c('div', {
    staticClass: "footer-buttons"
  }, [_c('e-button', {
    attrs: {
      "size": "m",
      "variant": "primary"
    },
    on: {
      "click": _vm.addSelected
    }
  }, [_vm._v(" Добавить "), _vm.getSelectedProducts.length !== 0 ? _c('div', {
    staticClass: "footer-buttons__quantity-products"
  }, [_vm._v(" " + _vm._s(_vm.getSelectedProducts.length) + " ")]) : _vm._e()]), _c('e-button', {
    attrs: {
      "size": "m",
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v("Отмена")])], 1)], 1), _c('mass-add-table', {
    on: {
      "addSelected": _vm.addSelected
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }