<template>
  <b-modal
    id="inventory-result"
    size="xl"
    hide-footer
    hide-header
    centered
    body-class="p-0 overflow-body"
  >
    <breadcrumbs
      style="margin-left: 16px"
      :is_inventory_result="true"
      :extra="extra"
    />
    <b-form-group
      label="Комментарий"
      label-for="comment"
      class="w-25 ml-3"
    >
      <b-form-textarea
        id="comment"
        :value="comment === null ? extra.comment : comment"
        rows="3"
        max-rows="6"
        :is-keyup="true"
        @input="changeComment"
      />
    </b-form-group>

    <b-card
      body-class="card-decoration p-0 overflow-body"
      style="margin-bottom: 0 !important; height: 55vh"
    >
      <div class="d-flex">
        <b-table
          show-empty
          :fields="table.fields"
          :items="items"
          :borderless="true"
          responsive
          empty-filtered-text="Нет товаров"
          details-td-class="p-0"
          :busy="loading_sheet"
          @scroll.native="onScroll"
          @row-clicked="getByCategory"
        >
          <template #table-busy>
            <div class="text-center pb-5">
              <b-spinner
                variant="primary"
                label="Загрузка..."
              />
              <div><strong>Загрузка...</strong></div>
            </div>
          </template>
          <template #empty="scope">
            <p class="center mt-3">{{ scope.emptyFilteredText }}</p>
          </template>

          <template
            v-if="items.length"
            #top-row
          >
            <td class="text-left"><b>Итого</b></td>
            <td class="text-right">
              <b>{{ formatNumbers(total.delta_total) }}</b>
            </td>
            <td class="text-right">
              <b>{{ formatNumbers(total.fact_total) }}</b>
            </td>
            <td class="text-right">
              <b>{{ formatNumbers(total.difference_total) }}</b>
            </td>
            <td class="text-right">
              <b>{{ formatNumbers(total.difference_sum_total) }}</b>
            </td>
          </template>

          <template #cell(delta_category)="{ item }">
            {{ formatNumbers(item.delta_category) }}
          </template>

          <template #cell(fact_category)="{ item }">
            {{ formatNumbers(item.fact_category) }}
          </template>

          <template #cell(difference_category)="{ item }">
            {{ formatNumbers(item.difference_category) }}
          </template>

          <template #cell(difference_sum_category)="{ item }">
            {{ formatNumbers(item.difference_sum_category) }}
          </template>

          <template #row-details="{ item }">
            <b-tr
              v-for="(row, index) of item.products"
              :key="index"
              class="p-md-3"
              style="display: flex; flex-direction: row; padding-right: 0 !important"
            >
              <div style="padding-left: 40px; display: flex; flex: 1; flex-direction: column">
                <span>{{ row.product.name }}</span>
              </div>
              <b-col class="table-numeric">
                {{ formatNumbers(row.delta) }}
              </b-col>
              <b-col class="table-numeric">{{ formatNumbers(row.fact) }}</b-col>
              <b-col class="table-numeric">{{ formatNumbers(row.diff) }}</b-col>
              <b-col class="table-numeric">
                {{ formatNumbers(row.diff_sum) }}
              </b-col>
            </b-tr>
          </template>

          <template #cell(category)="{ item }">
            <span class="cursor">
              <img
                src="/img/icons/menu_arrow_down.svg"
                style="margin-right: 18px; transition: 250ms"
                :style="item._showDetails ? '' : 'transform: rotate(-90deg)'"
              />
            </span>
            <span>{{ item.category?.name }}</span>
          </template>
        </b-table>
      </div>
    </b-card>
    <div class="card-control p-3">
      <b-button
        variant="outline-primary"
        :disabled="loading_sheet"
        @click="printDoc"
      >
        Печать сличительной ведомости
      </b-button>
      <div>
        <b-button
          variant="outline-primary"
          @click="hideModal"
        >
          Назад
        </b-button>
        <b-button
          variant="primary"
          class="ml-2"
          :disabled="loading_sheet"
          @click="fixed"
        >
          Провести
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
  import Breadcrumbs from '@/views/core/breadcrumbs/breadcrumbs'
  import { http } from '@/http/http'
  import { print_html } from '@/utils/print'

  export default {
    name: 'ResultModal',
    components: { Breadcrumbs },
    props: {
      loading_sheet: Boolean,
      extra: {
        type: Object,
        default: () => {}
      },
      comment: {
        type: String,
        default: null
      },
      items: Array,
      total: Object
    },
    data() {
      return {
        products: [],
        table: {
          fields: [
            {
              key: 'category',
              label: 'Группа товаров',
              tdClass: 'no-border-table p-3',
              sortable: true
            },
            {
              key: 'delta_category',
              label: 'Количество по учету',
              tdClass: 'no-border-table p-3',
              // thStyle: 'width:100px',
              sortable: true,
              class: 'text-right table-numeric',
              formatter: 'formatNumbers'
            },
            {
              key: 'fact_category',
              label: 'Количество по факту',
              tdClass: 'no-border-table p-3',
              sortable: true,
              class: 'text-right table-numeric',
              formatter: 'formatNumbers'
            },
            {
              key: 'difference_category',
              label: 'Разница по количеству',
              tdClass: 'no-border-table p-3',
              sortable: true,
              class: 'text-right table-numeric',
              formatter: 'formatNumbers'
            },
            {
              key: 'difference_sum_category',
              label: 'Разница по сумме, ₽',
              tdClass: 'no-border-table p-3',
              sortable: true,
              class: 'text-right table-numeric',
              formatter: 'formatNumbers'
            }
          ],
          items: [
            {
              category: 'test1',
              remainder: 20,
              delta: 18,
              difference: 12,
              difference_sum: 21
            },
            {
              category: 'test2',
              remainder: 21230,
              delta: 128,
              difference: 122,
              difference_sum: 221
            }
          ]
        }
      }
    },
    methods: {
      changeComment(value) {
        this.$emit('change_comment', value)
      },
      onScroll(event) {
        let bottomOfWindow = event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight
        if (bottomOfWindow) {
          this.$emit('load_items')
        }
      },
      formatNumbers(item) {
        return new Intl.NumberFormat('ru-RU', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        }).format(item)
      },
      hideModal() {
        this.$bvModal.hide('inventory-result')
      },
      fixed() {
        this.$emit('fixed')
      },
      openGroup() {},
      async getByCategory(item) {
        this.$set(item, '_showDetails', !item._showDetails)
        const { data } = await this.$apollo.query({
          query: require('../../pages/inventory/gql/ReadInventorySheetByCategory.graphql'),
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              category: item.category.id,
              document_head: this.extra.id
            }
          }
        })
        this.$set(item, 'products', data.ReadInventorySheetByCategory.sheets)
      },
      async printDoc() {
        const data = await http.get('/templates/inventarisation/sheet?id=' + this.extra.id)
        print_html(data.data)
      }
    }
  }
</script>

<!-- <style scoped lang="scss">

::v-deep() {

  .no-border-table {
    border: none !important;
  }

  .overflow-body {
    overflow: auto;
  }

  .b-table-details:hover {
    background-color: white;
    /*background-color: violet;*/
  }


}



  .no-border-table {
    border: none !important;
  }

  .overflow-body {
    overflow: auto;
  }

  .table-numeric {
    width: 160px;
    max-width: 160px;
    min-width: 160px;
    text-align: right;
  }
  .b-table-details:hover {
    background-color: white;
    /*background-color: violet;*/
  }
</style> -->

<style lang="scss" scoped>
  ::v-deep() {
    .no-border-table {
      border: none !important;
    }

    .overflow-body {
      overflow: auto;
    }

    .b-table-details:hover {
      background-color: white;
      /*background-color: violet;*/
    }

    .card-decoration {
      border: 1px solid #e1e1e1 !important;
      border-radius: 2px !important;
    }

    .no-margin {
      margin-bottom: 0;
    }

    .articul {
      font-size: 12px;
      color: #888888;
      margin-top: 5px;
    }
    .btn.disabled,
    .btn:disabled {
      opacity: 0.5;
      &:hover {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    .table-numeric {
      width: 160px;
      max-width: 160px;
      min-width: 160px;
      text-align: right;
    }

    .card-control {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .table-numeric {
    width: 160px;
    max-width: 160px;
    min-width: 160px;
    text-align: right;
  }

  .card-control {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
</style>
