<template>
  <section id="movement-modal">
    <b-modal
      id="product-movement"
      size="xl"
      centered
      header-class="mb-0"
      hide-footer
      @show="addListener"
    >
      <template #modal-header="{ close }">
        <h5 class="mb-1">{{ product?.name }}</h5>
        <b-button
          class="close"
          @click="close()"
        >
          <img
            src="/img/icons/modal_close.svg"
            alt=""
          />
        </b-button>
      </template>
      <div class="movement-modal-inner">
        <b-modal
          id="accept-doc-modal"
          centered
        >
          <template #modal-header>
            <h5>Подтверждение перехода к документу</h5>
          </template>
          Вы действительно хотите закрыть текущее окно и перейти к документу?
          <template #modal-footer="{ cancel }">
            <div class="d-flex w-75">
              <b-button
                variant="primary"
                @click="openDocument"
              >
                Продолжить
              </b-button>
              <b-button
                variant="outline-primary"
                @click="cancel"
              >
                Отмена
              </b-button>
            </div>
          </template>
        </b-modal>
        <span class="mb-4">Артикул:{{ product?.articul ?? '----' }}</span>
        <b-row class="w-75">
          <b-col style="max-width: fit-content">
            <b-form-group label="Период отображения">
              <work-period
                style="width: 100%"
                @select="first = true"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Место хранения">
              <i-select
                id="storage"
                v-model="variables.storage"
                filterable
                transfer
                @on-change="setStorage"
              >
                <i-option
                  v-for="item in storage_list"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </i-option>
              </i-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Операции">
              <i-select
                v-model="variables.operation"
                multiple
                transfer
                :max-tag-count="1"
                :max-tag-placeholder="(val) => '+ Еще ' + val"
                @on-change="setOperations"
              >
                <i-option-group label="Документы прихода">
                  <i-option
                    v-for="item of incoming_operations"
                    :key="item.key"
                    :value="item.key"
                  >
                    {{ item.label }}
                  </i-option>
                </i-option-group>
                <i-option-group label="Документы расхода">
                  <i-option
                    v-for="item of outgoing_operations"
                    :key="item.key"
                    :value="item.key"
                  >
                    {{ item.label }}
                  </i-option>
                </i-option-group>
                <i-option-group label="Кассовая реализация">
                  <i-option
                    v-for="item of cash_operations"
                    :key="item.key"
                    :value="item.key"
                  >
                    {{ item.label }}
                  </i-option>
                </i-option-group>
                <i-option-group label="Инвентаризация">
                  <i-option
                    v-for="item of inventory_operation"
                    :key="item.key"
                    :value="item.key"
                  >
                    {{ item.label }}
                  </i-option>
                </i-option-group>
                <i-option-group label="Перемещение">
                  <i-option value="movement">Перемещение</i-option>
                </i-option-group>
                <i-option-group label="Производство">
                  <i-option value="manufacture">Калькуляция</i-option>
                  <i-option value="reverse_manufacture">Перефасовка</i-option>
                </i-option-group>
              </i-select>
            </b-form-group>
          </b-col>
        </b-row>
        <resizable-table
          style="height: 60vh"
          class="mb-3"
          table_name="product-movement"
          :default_fields="fields"
          :items="movement"
          @scroll-down="scrollDown"
          @row-click="goToDoc"
        >
          <template #body_number="{ item }">
            <div style="display: flex; flex-direction: column">
              <span>{{ item.number }}</span>
              <span
                style="color: #888888; font-size: 12px"
                class="mt-1"
                >{{ operation_mapping[item.operation] }}</span
              >
            </div>
          </template>

          <template #body_date="{ value }">
            {{ formatDate(value) }}
          </template>

          <template #body_price="{ value }">
            {{ formatPrice(value) }}
          </template>

          <template #body_sum="{ value }">
            {{ formatPrice(value) }}
          </template>

          <template #body_user="{ value }">
            {{ formatUser(value) }}
          </template>

          <template #body_fixed="{ value }">
            {{ value ? 'Проведен' : 'Разоприходован' }}
          </template>

          <template #body_storage="{ item }">
            <template v-if="['income', 'return'].includes(item.operation)">
              <div style="display: flex; flex-direction: column">
                <span>{{ item.supplier?.name }}</span>
                <div>
                  <b-icon
                    icon="arrow-right"
                    class="mr-2"
                  />
                  <span>{{ item.storage?.name }}</span>
                </div>
              </div>
            </template>
            <template v-else-if="item.operation === 'movement' || item.operation === 'purchase'">
              <div style="display: flex; flex-direction: column">
                <span>{{ item.storage?.name }}</span>
                <div>
                  <b-icon
                    icon="arrow-right"
                    class="mr-2"
                  />
                  <span>{{ item.targetStorage?.name ?? 'Покупатель' }}</span>
                </div>
              </div>
            </template>
            <template v-else-if="item.operation === 'return'">
              <div style="display: flex; flex-direction: column">
                <div>
                  <b-icon
                    icon="arrow-right"
                    class="mr-2"
                  />
                  <span>{{ item.supplier?.name }}</span>
                </div>
                <span>{{ item.storage?.name }}</span>
              </div>
            </template>
            <template v-else>
              {{ item.storage?.name }}
            </template>
          </template>

          <template #body_remainder="{ item }">
            {{ +item.remainder?.toFixed(3) }}
            <span
              :class="{
                error: item.delta < 0,
                success: item.delta >= 0
              }"
              >({{ item.delta > 0 ? '+' : '' }}{{ +item.delta?.toFixed(3) }})</span
            >
          </template>
        </resizable-table>
        <!--          <b-table-->
        <!--            show-empty-->
        <!--            class="table-docs"-->
        <!--            sticky-header-->
        <!--            style="min-height: calc(60vh - 3px)"-->
        <!--            :responsive="true"-->
        <!--            :fields="fields"-->
        <!--            :items="movement"-->
        <!--            empty-text="Нет движения"-->
        <!--            tbody-tr-class="cursor"-->
        <!--            tbody-class="p-0 m-0 scroll-table-body"-->
        <!--            table-class="scroll-table"-->
        <!--            @row-clicked="goToDoc"-->
        <!--          >-->
        <!--            <template #cell(number)="{ item }">-->
        <!--              <div style="display: flex; flex-direction: column">-->
        <!--                <span>{{ item.number }}</span>-->
        <!--                <span style="color: #888888; font-size: 12px" class="mt-1">{{-->
        <!--                  operation_mapping[item.operation]-->
        <!--                }}</span>-->
        <!--              </div>-->
        <!--            </template>-->

        <!--            <template #cell(storage)="{ item }">-->
        <!--              <template v-if="['income', 'return'].includes(item.operation)">-->
        <!--                <div style="display: flex; flex-direction: column">-->
        <!--                  <span>{{ item.supplier.name }}</span>-->
        <!--                  <div>-->
        <!--                    <b-icon icon="arrow-right" class="mr-2" />-->
        <!--                    <span>{{ item.storage.name }}</span>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </template>-->
        <!--              <template v-else-if="item.operation === 'movement'">-->
        <!--                <div style="display: flex; flex-direction: column">-->
        <!--                  <span>{{ item.storage.name }}</span>-->
        <!--                  <div>-->
        <!--                    <b-icon icon="arrow-right" class="mr-2" />-->
        <!--                    <span>{{ item.targetStorage.name }}</span>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </template>-->
        <!--              <template v-else-if="item.operation === 'return'">-->
        <!--                <div style="display: flex; flex-direction: column">-->
        <!--                  <div>-->
        <!--                    <b-icon icon="arrow-right" class="mr-2" />-->
        <!--                    <span>{{ item.supplier.name }}</span>-->
        <!--                  </div>-->
        <!--                  <span>{{ item.storage.name }}</span>-->
        <!--                </div>-->
        <!--              </template>-->
        <!--              <template v-else>-->
        <!--                {{ item.storage.name }}-->
        <!--              </template>-->
        <!--            </template>-->

        <!--            <template #cell(remainder)="{ item }">-->
        <!--              {{ item.remainder }}-->
        <!--              <span-->
        <!--                v-bind:class="{-->
        <!--                  error: item.delta < 0,-->
        <!--                  success: item.delta >= 0-->
        <!--                }"-->
        <!--                >({{ item.delta > 0 ? '+' : ''-->
        <!--                }}{{ +item.delta.toFixed(3) }})</span-->
        <!--              >-->
        <!--            </template>-->
        <!--          </b-table>-->
        <div class="mb-3 total-info w-75">
          <span style="flex: 1"
            >Остаток на начало периода:
            <span
              :class="{
                error: remainder.begin_remainder < 0,
                success: remainder.begin_remainder > 0
              }"
              >{{ remainder.begin_remainder }}</span
            ></span
          >
          <span style="flex: 1"
            >Остаток на конец периода:
            <span
              :class="{
                error: remainder.end_remainder < 0,
                success: remainder.end_remainder > 0
              }"
              >{{ remainder.end_remainder }}</span
            ></span
          >
          <span style="flex: 1"
            >Текущий остаток:
            <span
              :class="{
                error: getRemainder < 0,
                success: getRemainder > 0
              }"
              >{{ getRemainder }}</span
            ></span
          >
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { ProductModel } from '@/models/product.model'
  import ResizableTable from '@/components/ResizableTable'
  import { FieldsModel } from '@/models/fields.model'
  import WorkPeriod from '@/components/WorkPeriod'

  export default {
    name: 'ProductMovementModal',
    components: { WorkPeriod, ResizableTable },
    props: {
      product: {
        type: ProductModel,
        default: () => new ProductModel()
      }
    },
    apollo: {
      Storages: {
        query: require('../../operational-processes/gql/getStorages.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch.id
          }
        },
        result({ data }) {
          this.storage_list = data.Storages || []
          if (
            (!this.variables?.storage && this.storage_list.length) ||
            !this.storage_list.some((el) => el.id === this.variables.storage)
          )
            this.variables.storage = this.storage_list?.[0]?.id
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },
      ProductMovement: {
        query: require('../gql/productMovement.graphql'),
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables() {
          return {
            input: {
              ...this.variables,
              storage: [this.variables.storage],
              branch: this.currentBranch.id,
              period: {
                begin: this.currentDate.date[0],
                end: this.currentDate.date[1]
              },
              product: this.product.id,
              pagination: {
                skip: this.pagination.skip,
                take: this.pagination.take
              }
            }
          }
        },
        result({ data }) {
          const oldspec = !this.first ? this.movement || [] : []

          this.movement = data?.ProductMovement?.productMovement ?? []

          if (!this.first) {
            // const newDoc = new PaymentHeadModel(data.IncomingHead)
            if (!this.forwardDirection) {
              this.movement = [...oldspec, ...(data?.ProductMovement?.productMovement ?? [])]
            } else {
              const initialHeight = document.querySelector('.table-docs').scrollHeight
              this.movement = [...(data?.ProductMovement?.productMovement ?? []), ...oldspec]
              if (oldspec.length > 0)
                this.$nextTick(() => {
                  document.querySelector('.table-docs').scrollTop =
                    document.querySelector('.table-docs').scrollHeight - initialHeight - 10
                })
            }
          }

          // this.movement = data?.ProductMovement?.productMovement ?? []
          if (this.first) {
            this.remainder = {
              begin_remainder: +data?.ProductMovement?.begin_remainder?.toFixed(3) || 0,
              end_remainder: +data?.ProductMovement?.end_remainder?.toFixed(3) || 0
            }
          }
          this.total = data?.ProductMovement?.total ?? 0
          this.first = false
        },
        skip() {
          return !this.variables.storage || !this.product?.id
        }
      }
    },
    data() {
      return {
        fields: [
          {
            key: 'number',
            label: 'Документ',
            thClass: 'white-bg',
            checked: true,
            width: 135
          },
          {
            key: 'date',
            label: 'Дата',
            formatter: (value) => this.formatDate(value),
            class: 'center',
            thClass: 'white-bg',
            checked: true,
            width: 135
          },
          {
            key: 'storage',
            label: 'Движение',
            class: 'center',
            thClass: 'white-bg',
            checked: true,
            width: 135
          },
          // { key: 'targetStorage', label: 'МХ куда' },
          {
            key: 'price',
            label: 'Цена, ₽',
            thStyle: 'min-width:100px',
            formatter: (value) => this.formatPrice(value),
            class: 'center',
            thClass: 'white-bg',
            checked: true,
            width: 135
          },
          {
            key: 'sum',
            label: 'Сумма, ₽',
            thStyle: 'min-width:100px',
            formatter: (value) => this.formatPrice(value),
            class: 'center',
            thClass: 'white-bg',
            checked: true,
            width: 135
          },
          {
            key: 'remainder',
            label: 'Остаток',
            thStyle: 'min-width:130px',
            class: 'center',
            thClass: 'white-bg',
            checked: true,
            width: 135
          },
          {
            key: 'user',
            label: 'Менеджер',
            formatter: (value) => this.formatUser(value),
            class: 'center',
            thClass: 'white-bg',
            checked: true,
            width: 180
          },
          {
            key: 'fixed',
            label: 'Состояние',
            formatter: (value) => (value ? 'Проведен' : 'Разоприходован'),
            class: 'center',
            thClass: 'white-bg',
            checked: true,
            width: 135
          }
        ].map((el) => new FieldsModel(el)),
        scrollExist: true,
        forwardDirection: false,
        first: true,
        total: 0,
        remainder: {
          begin_remainder: 0,
          end_remainder: 0
        },
        storage_list: [],
        movement: [],
        variables: {
          storage: null,
          period: [],
          operation: []
        },
        pagination: {
          skip: 0,
          take: 20
        },
        tmpItem: null,
        incoming_operations: [
          { key: 'income', label: 'Приемка' },
          { key: 'bonus', label: 'Бонус' },
          { key: 'commission', label: 'Комиссия' }
        ],
        outgoing_operations: [
          { key: 'write_off', label: 'Списание' },
          { key: 'return', label: 'Возврат' },
          { key: 'cashless_sale', label: 'Б/Н продажа' }
        ],
        cash_operations: [
          { key: 'purchase', label: 'Продажа по кассе' },
          { key: 'payback', label: 'Возврат по кассе' }
        ],
        inventory_operation: [
          { key: 'inventory_income', label: 'Излишки' },
          { key: 'inventory_outgoing', label: 'Недостача' }
        ],
        default_operations: [
          'income',
          'bonus',
          'commission',
          'write_off',
          'return',
          'cashless_sale',
          'purchase',
          'payback',
          'inventory_income',
          'inventory_outgoing',
          'movement',
          'manufacture',
          'reverse_manufacture'
        ],
        operation_mapping: {
          income: 'Приход',
          bonus: 'Бонус',
          write_off: 'Списание',
          return: 'Возврат',
          cashless_sale: 'Б/Н продажа',
          purchase: 'Продажа',
          payback: 'Возврат',
          inventory: 'Инвентаризация',
          inventarisation: 'Инвентаризация',
          inventory_income: 'Инвентаризация',
          inventory_outgoing: 'Инвентаризация',
          movement: 'Перемещение',
          manufacture: 'Калькуляция',
          reverse_manufacture: 'Перефасовка'
        }
      }
    },
    mounted() {
      this.prepareDates()
      this.variables.operation = this.default_operations
      this.$apollo.queries.ProductMovement.refetch()
    },
    methods: {
      addListener() {
        this.first = true
        this.$apollo.queries.ProductMovement.refetch()
      },
      setStorage(val) {
        this.pagination = {
          skip: 0,
          take: 20
        }
        this.first = true
        this.variables.storage = val
      },
      setOperations() {
        this.pagination = {
          skip: 0,
          take: 20
        }
        this.first = true
      },
      setDate() {
        this.pagination = {
          skip: 0,
          take: 20
        }
        this.first = true
      },
      scrollDown() {
        if (this.$apollo.queries.ProductMovement.loading || this.pagination.skip + this.pagination.take >= this.total)
          return
        this.pagination.skip +=
          this.pagination.skip > this.total ? this.total - this.pagination.skip : this.pagination.take
      },
      prepareDates() {
        const one_month_ago = new Date()
        const today = new Date()

        this.variables.period = [new Date(one_month_ago.setMonth(one_month_ago.getMonth() - 1)), today]
      },
      formatDate(date) {
        return new Intl.DateTimeFormat('ru-RU').format(new Date(date))
      },
      formatPrice(price) {
        return new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB',
          maximumFractionDigits: 3,
          minimumFractionDigits: 0
        }).format(price)
      },
      formatUser(user) {
        if (!user) return '-'
        return (user?.first_name ?? '' + user?.last_name ?? '') || ' - '
      },
      goToDoc(item) {
        this.tmpItem = item
        this.$bvModal.show('accept-doc-modal')
      },
      openDocument() {
        if (this.tmpItem.operation === 'income' || this.tmpItem.operation === 'bonus') {
          this.$router.push({
            name: 'operation-process.overhead.incoming.document.edit',
            params: { id: this.tmpItem.document }
          })
        } else if (this.tmpItem.operation === 'movement') {
          this.$router.push({
            name: 'operation-process.overhead.movement.document.edit',
            params: { id: this.tmpItem.document }
          })
        } else if (this.tmpItem.operation === 'inventarisation') {
          this.$router.push({
            name: 'operation-process.overhead.inventory.document.edit',
            params: { id: this.tmpItem.document }
          })
        } else if (this.tmpItem.operation === 'cashless_sale') {
          this.$router.push({
            name: 'operation-process.overhead.cashless.document.edit',
            params: { id: this.tmpItem.document }
          })
        } else if (['return', 'write_off'].includes(this.tmpItem.operation)) {
          this.$router.push({
            name: 'operation-process.overhead.outgoing.document.edit',
            params: { id: this.tmpItem.document }
          })
        } else if (this.tmpItem.operation === 'purchase') {
          this.$router.push({
            name: 'operation-process.cashbox.detail',
            params: { id: this.tmpItem.document }
          })
        } else if (this.tmpItem.operation === 'manufacture') {
          this.$router.push({
            name: 'manufacture.equipment.document.edit',
            params: { id: this.tmpItem.document }
          })
        }
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch',
        currentDate: 'operprocess/getDateRange'
      }),
      getRemainder() {
        return +this.product?.remainders?.find((el) => el.storage.id === this.variables.storage)?.value?.toFixed(2) || 0
      }
    }
  }
</script>

<style scoped lang="scss">
  .movement-modal-inner {
    display: flex;
    flex-direction: column;
  }

  .total-info {
    display: flex;
    flex-direction: row;
    border: 1px solid #e1e1e1;
  }

  .total-info > span {
    border-right: 1px solid #e1e1e1;
    padding: 9px 16px;
    color: black;
  }

  .total-info > span:last-child {
    border-right: none;
  }

  .success {
    color: #00cb91;
  }
</style>
